import { useEffect, useState, createContext } from 'react'
import firebase from 'firebase'
import { InitializeFirebase } from '../services/firebase'
import axios from 'axios'

type AuthValue = {
  currentUser: firebase.User | null
  isAuthChecked: boolean
  isTokenChecked: boolean
  isRegistered: boolean
  setAccessToken: (x: string) => void
}
const initialState: AuthValue = {
  currentUser: null,
  isAuthChecked: false,
  isTokenChecked: false,
  isRegistered: false,
  setAccessToken: (x: string) => {},
}
export const AuthContext = createContext<AuthValue>(initialState)

export const LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY = 'sign-in-email'
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'Authorization'

const { auth } = InitializeFirebase()

export const signInAnonymously = async () => {
  try {
    await auth.signInAnonymously()
  } catch (err) {
    console.log(err)
  }
}

export const signIn = async (email, url) => {
  const conf = {
    url,
    handleCodeInApp: true,
  }
  try {
    await auth.sendSignInLinkToEmail(email, conf)
    window.localStorage.setItem(LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY, email)
  } catch (err) {
    console.error(err)
  }
}

export const login = async () => {
  if (!auth.isSignInWithEmailLink(window.location.href)) {
    return
  }
  const email = window.localStorage.getItem(LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY)
  if (!email) {
    return
  }
  try {
    await auth.signInWithEmailLink(email, window.location.href)
  } catch (err) {
    console.error(err)
  }
}

export const logout = async () => {
  try {
    window.localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  } catch (err) {
    console.error(err)
  }
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null)
  const [isAuthChecked, setIsAuthChecked] = useState(false)
  const [isTokenChecked, setIsTokenChecked] = useState(false)
  const [isRegistered, setIsRegistered] = useState(false)

  const setAccessToken = (token: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token)
    setIsRegistered(true)
    setIsTokenChecked(true)
    setIsAuthChecked(true)
    setCurrentUser({uid: ''} as firebase.User)
  }

  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  
  if (authToken) {
    axios.defaults.headers.common = { [LOCAL_STORAGE_AUTH_TOKEN_KEY]: `Bearer ${authToken}` }
  }

  const value: AuthValue = {
    currentUser,
    isAuthChecked,
    isTokenChecked,
    isRegistered,
    setAccessToken,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
