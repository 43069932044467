import { useVideojs } from 'react-videojs-hook'
import { FC, useRef, useState } from 'react'

import { useAnalyticsEventMutation } from '@alpha/core'

import 'video.js/dist/video-js.css'
import axios from 'axios'

type Props = {
  keyName?: string
  src: string
  thumb: string
  size: {
    height?: number
    width?: number
  }
  className?: string
}

const Video: FC<Props> = ({ src, size, thumb, className, keyName }) => {
  const [submitEvent] = useAnalyticsEventMutation()
  const startPlayingRef = useRef(false);

  const { vjsId, vjsRef, vjsClassName } = useVideojs({
    src,
    poster: thumb,
    className,
    bigPlayButton: true,
    bigPlayButtonCentered: true,
    controls: true,
    autoplay: false,
    responsive: true,
    onPlay: async (currentTime) => {
      if (!startPlayingRef.current) {
        startPlayingRef.current = true;
        await axios.post(
          `${process.env.REACT_APP_REST_API_URL}/alpha/v1/student/video-view-count`,
          {
            keyName
          }
        )
        console.log('Start Playing: ' + keyName)
      }
      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onPlay',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: new Date().getTime(),
            date: new Date(),
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
          }),
        },
      })
      console.log('Video played at:', currentTime)
    },
    onPause: async (currentTime) => {
      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onPause',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: new Date().getTime(),
            date: new Date(),
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
          }),
        },
      })
      console.log('Video paused at:', currentTime)
    },
    onEnd: async (currentTime) => {
      await submitEvent({
        variables: {
          input: JSON.stringify({
            name: 'onEnd',
            category: 'video',
            playtime: currentTime,
            video: src.split('/').pop() ?? '',
            date_ms: new Date().getTime(),
            date: new Date(),
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate(),
          }),
        },
      })
      console.log('Video ended at:', currentTime)
    },
    onTimeUpdate: (currentTime) => {
      console.log('Video current time is:', currentTime)
    },
  })

  return (
    <div data-vjs-player>
      <video
        ref={vjsRef}
        id={vjsId}
        className={vjsClassName}
        width={size.width}
        height={size.height}
      />
    </div>
  )
}

export default Video
