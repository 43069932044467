import {
  Bending,
  EnduranceRun,
  FitnessTest,
  Grip,
  HandballThrow,
  ShuttleRun,
  SideJump,
  SitUps,
  SprintRun,
  StandingJump,
} from './fitnessTest'
import _ from 'lodash'
import moment from 'moment'
import { School } from '~/context'

export const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}

export const gradesForSchoolCategoryCode = (code: string) => {
  switch (code) {
    case 'B1':
      return 6
    case 'C1':
      return 3
    case 'C2':
      return 9
    case 'D1':
      return 4
    case 'D2':
      return 6
    default:
      return 3
  }
}

export const reCalcTestResult = (testResult, age, gender) => {
  const tests = [
    'grip',
    'sitUps',
    'bending',
    'sideJump',
    'enduranceRun',
    'shuttleRun',
    'sprintRun',
    'standingJump',
    'handballThrow',
  ]
  let _testResult = _.cloneDeep(testResult)

  tests.forEach((key) => {
    if (_testResult[key]) {
      if (_testResult[key].isNotMeasurable) {
        _testResult[key].value = 0
        _testResult[key].points = 0
        _testResult[key].rating = 0
        _testResult[key].memo = ''
        if (key === 'sitUps') {
          _testResult[key].value = '-'
          _testResult[key].points = '-'
        }

        if (key === 'enduranceRun') {
          _testResult[key].runningTime = 0
          _testResult[key].runningTimeSeconds = 0
        }
      } else if (key === 'enduranceRun') {
        let runningTimeSeconds = moment
          .duration({
            minute: _testResult[key].runningTime,
            second: _testResult[key].runningTimeSeconds,
          })
          .asSeconds()

        _testResult[key].value = EnduranceRun.computeValue(runningTimeSeconds)
        _testResult[key].points = EnduranceRun.computePoints(
          runningTimeSeconds,
          age,
          gender?.toUpperCase() ?? 'MALE',
        )
        _testResult[key].rating = EnduranceRun.getRating(
          runningTimeSeconds,
          age,
          gender?.toUpperCase() ?? 'MALE',
        )
      }

      if (_testResult[key].points) {
        _testResult[key].nextPoints = computeNextPointValue(
          key,
          _testResult[key].value,
          _testResult[key].points,
          age,
          gender?.toUpperCase() ?? 'MALE',
        )
      }
    }
  })

  _testResult.points = FitnessTest.computePoints(_testResult)
  _testResult.rank = FitnessTest.computeGrade(
    _testResult.points,
    isNaN(age) ? 6 : age,
  )

  return _testResult
}

export const getShowRanking = (testResult, isEnduranceRun) => {
  if (!testResult) return false
  const tests = [
    'grip',
    'sitUps',
    'bending',
    'sideJump',
    isEnduranceRun ? 'enduranceRun' : 'shuttleRun',
    'sprintRun',
    'standingJump',
    'handballThrow',
  ]
  for (const key of tests) {
    if (!testResult[key] || testResult[key].isNotMeasurable) return false
    if (testResult[key].value === null || testResult[key].value === undefined) return false
    if (testResult[key].value === 0 && key !== 'sitUps') return false
  }
  return true
}

export const computeNextPointValue = (type, value, point, age, gender) => {
  const _gender = gender?.toUpperCase() ?? 'MALE'
  const getNextPointValue = (ranges, value) => {
    const nextPoint = type.toLowerCase() === 'sprintrun' ? ranges[point] : ranges[point + 1]
    if (nextPoint) {
      return nextPoint.Range[0] - value
    }
  }
  switch (type?.toLowerCase()) {
    case 'grip': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = Grip.elementarySchoolRanges.MALE
        } else {
          ranges = Grip.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = Grip.elementarySchoolRanges.FEMALE
        } else {
          ranges = Grip.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'situps': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = SitUps.elementarySchoolRanges.MALE
        } else {
          ranges = SitUps.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = SitUps.elementarySchoolRanges.FEMALE
        } else {
          ranges = SitUps.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'bending': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = Bending.elementarySchoolRanges.MALE
        } else {
          ranges = Bending.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = Bending.elementarySchoolRanges.FEMALE
        } else {
          ranges = Bending.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'sidejump': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = SideJump.elementarySchoolRanges.MALE
        } else {
          ranges = SideJump.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = SideJump.elementarySchoolRanges.FEMALE
        } else {
          ranges = SideJump.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'endurancerun': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = EnduranceRun.middleSchoolRanges.MALE
        } else {
          ranges = EnduranceRun.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = EnduranceRun.middleSchoolRanges.FEMALE
        } else {
          ranges = EnduranceRun.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'shuttlerun': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = ShuttleRun.elementarySchoolRanges.MALE
        } else {
          ranges = ShuttleRun.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = ShuttleRun.elementarySchoolRanges.FEMALE
        } else {
          ranges = ShuttleRun.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'sprintrun': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = SprintRun.elementarySchoolRanges.MALE
        } else {
          ranges = SprintRun.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = SprintRun.elementarySchoolRanges.FEMALE
        } else {
          ranges = SprintRun.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'standingjump': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = StandingJump.elementarySchoolRanges.MALE
        } else {
          ranges = StandingJump.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = StandingJump.elementarySchoolRanges.FEMALE
        } else {
          ranges = StandingJump.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
    case 'handballthrow': {
      let ranges = {}
      if (_gender === 'MALE') {
        if (age < 12) {
          ranges = HandballThrow.elementarySchoolRanges.MALE
        } else {
          ranges = HandballThrow.middleSchoolRanges.MALE
        }
      } else {
        if (age < 12) {
          ranges = HandballThrow.elementarySchoolRanges.FEMALE
        } else {
          ranges = HandballThrow.middleSchoolRanges.FEMALE
        }
      }
      return getNextPointValue(ranges, value)
    }
  }
}

export const computeNextGradeValue = (grade, points, age) => {
  if (age < 6) {
    age = 6
  } else if (age > 19) {
    age = 19
  }
  const ranges = FitnessTest.ranges[age]
  const nextGrade = String.fromCharCode(grade.charCodeAt(0) - 1)
  const nextGradeRanges = ranges[nextGrade]
  if (nextGradeRanges) {
    return {grade: nextGrade, points: nextGradeRanges.Range[0] - points}
  }
}

export function computeAge(birthday) {
  birthday = new Date(birthday) || new Date()
  let month = new Date().getMonth() + 1
  let currentYear = getCurrentTestYear()
  currentYear = month > 3 ? currentYear : currentYear - 1
  return computeAgeFromReferenceDate(birthday, new Date(`${currentYear}-3-31`))
}

export function ageFromSchool(
  schoolCategoryCode: string,
  grade: number,
) {
  return grade + initialAgeForSchoolCategoryCode(schoolCategoryCode) - 1
}

export const initialAgeForSchoolCategoryCode = (code: string) => {
  switch (code) {
    case 'B1':
      return 6
    case 'C1':
      return 12
    case 'C2':
      return 6
    case 'D1':
      return 15
    case 'D2':
      return 12
    default:
      return 6
  }
}

export function computeAgeFromReferenceDate(birthday: Date, refDate: Date) {
  let timeDiff = Math.abs(refDate.getTime() - birthday.getTime())
  let age_dt = new Date(timeDiff)

  return Math.abs(age_dt.getFullYear() - 1970)
}

export function getCurrentTestYear() {
  return getTestYear(new Date())
}

export function getTestYear(date: Date) {
  let year = date.getFullYear()
  // 0 to 11
  let month = date.getMonth()
  return month > 2 ? year : year - 1
}

export function schoolCodeHaveEnduranceRun(schoolCategoryCode: string) {
  return ['C1', 'D1'].includes(schoolCategoryCode)
}

export function schoolHasEnduranceTest(school) {
  return (
    school &&
    school.isEnduranceRunInputActive &&
    schoolCodeHaveEnduranceRun(school.schoolCategoryCode)
  )
}

export function parseTeacherType(v: string) {
  if (v === 'SCHOOL_ADMIN') {
    return '全体管理者'
  } else if (v === 'GRADE_ADMIN') {
    return '学年管理者'
  } else {
    return '担任'
  }
}

export function convertJapNumbers(input: string) {
  const chars = {'０': '0', '１': '1', '２': '2', '３': '3', '４': '4', '５': '5', '６': '6', '７': '7', '８': '8', '９': '9'};
  return input.replace(/[０１２３４５６７８９]/g, m => chars[m])
}

export function getTotalQuestionnaires(school: any, isElementary: boolean) {
  return school?.attributes?.prefectureCode === 9
    ? 19
    : school?.attributes?.prefectureCode === 45
      ? isElementary ? 14 : 13
      : isElementary ? 8 : 9
}

const createBlankArr = (length) => {
  return Array.from({length: length}, (_, i) => i + 1)
}

export const getQuestionnairesArr = (school: any, isElementary: boolean) => {
  switch (school?.attributes?.prefectureCode) {
    case 9:
      return createBlankArr(19)
    case 16:
      return isElementary ? createBlankArr(11) : createBlankArr(9)
    case 45:
      return isElementary ? [1, 2, 3, 4, 5, 6, 7, 8, 9, '10-1', '10-2', 11, 12, 13] : createBlankArr(13)
    default:
      return isElementary ? createBlankArr(8) : createBlankArr(9)
  }
}