import {useEffect, useState} from 'react'

import {Modal} from 'antd'

import {useGradesLazyQuery,} from '@alpha/core'

import {Dashboard} from '../layout/Dashboard'
import {DashboardNavGraphItem} from '../navGraph/DashboardNavGraphItem'
import {TestInputs} from "../inputs/TestInputs";
import {CloseOutlined} from "@ant-design/icons";
import {WarnIcon} from "../icons/WarnIcon";
import {schoolCodeHaveEnduranceRun} from "~/utils"
import { useStudentMeApiQuery, useStudentTestsApiQuery } from '~/utils/hooks'
import {currentYear} from '~/utils/constants'
import { useHistory } from 'react-router-dom'


const TestInputPage = ({
                         graphItem,
                         isOpen,
                         setIsOpen,
                       }: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {  
  const [showNotiModals, setShowNotiModals] = useState<any>()
  const [isStudentInputActive, setStudentInputActive] = useState<any>()
  const [testYear] = useState(currentYear)
  const [firstLoad, setFirstLoad] = useState(true)
  const history = useHistory();
  const [enduranceRunInputActive, setEnduranceRunInputActive] = useState(false)

  const { data: meData, loading: meDataLoading } = useStudentMeApiQuery()
  const { data: examsData, loading: examsDataLoading } = useStudentTestsApiQuery()

  const [getGrades, {data: gradesData}] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  // testYears
  useEffect(() => {
    if (!meData?.school?._id) {
      return
    }

    const school_IsEnduranceRunInputActive = meData?.school?.isEnduranceRunInputActive && schoolCodeHaveEnduranceRun(meData?.school?.schoolCategoryCode)
    setEnduranceRunInputActive(school_IsEnduranceRunInputActive)
  }, [meData?.school, examsData])

  // getGrades
  useEffect(() => {
    if (!meData?.school?._id) {
      return
    }

    if (firstLoad) {
      setFirstLoad(false)
      setStudentInputActive(meData?.school?.isStudentInputActive)
      setShowNotiModals(!meData?.school?.isStudentInputActive)
      const schoolId = meData?.school?._id
  
      getGrades({
        variables: {
          input: {
            schoolId,
            testYear,
          },
        },
      })
    }
  }, [testYear, getGrades, meData?.school, firstLoad])

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  if (!meData?.school?._id || !gradesData) {
    return null
  }

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customNavigate
      onClickNavigate={() => history.push('/home')}
    >
      <div className="p-5">
        {!examsDataLoading && !meDataLoading &&
        <TestInputs testYear={currentYear} hasEnduranceTest={enduranceRunInputActive} student={meData}
                    isStudentInputActive={isStudentInputActive} testResults={examsData}/>}

        <Modal
          visible={showNotiModals}
          onCancel={() => setShowNotiModals(false)}
          width={900}
          closeIcon={<CloseOutlined className="text-base" style={{color: '#036EB8'}}/>}
          footer={null}
          centered={true}
          bodyStyle={{border: '3px solid #036EB8', borderRadius: 4}}
        >
          <div className="p-10 flex flex-col justify-center items-center text-base font-black"
               style={{color: '#B80303'}}>
            <div className="flex">
              {WarnIcon}
              <span>
                ただいま
                <ruby>測定結果<rt className="text-tiny">そくていけっか</rt></ruby>
                を
                <ruby>入力<rt className="text-tiny">にゅうりょく</rt></ruby>
                できません。
              </span>
            </div>
            <span>
              <ruby>設定<rt className="text-tiny">せってい</rt></ruby>
              を
              <ruby>変更<rt className="text-tiny">へんこう</rt></ruby>
              したい
              <ruby>場合<rt className="text-tiny">ばあい</rt></ruby>
              は
              <ruby>先生<rt className="text-tiny">せんせい</rt></ruby>
              にお
              <ruby>問<rt className="text-tiny">と</rt></ruby>
              い
              <ruby>合<rt className="text-tiny">あ</rt></ruby>
              わせください。</span>
          </div>
        </Modal>
      </div>
    </Dashboard>
  )
}

export default TestInputPage
