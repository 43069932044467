import React, {useCallback} from 'react'
import {signInAnonymously} from '@alpha/core'
import {Button} from "antd";
import {Anchor} from "../../sergeimeza/uikit-react";

const ISMSLogo = () => (
  <img
    className="w-14"
    src="https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/isms.jpg"
    alt="isms"
  />
)

const SignInPage = () => {
  const handleSignInBtn = useCallback(async () => {
    await signInAnonymously()
  }, [])

  return (
    <>
      <div
        className="w-full h-screen welcome-background"
        style={{background: `url(/images/Welcome.png) no-repeat center center fixed`}}
      >
        <div className="flex w-full justify-center pt-10">
          <ruby className="welcome-text-ja">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ようこそ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <rt className="welcome-text-en pt-30">Welcome</rt>
          </ruby>
        </div>
        <div className="agreement-text text-center text-white">
          <p className="text-10px">
            続行することで、Pestalozzi Technology株式会社の
            <Anchor
              href="https://pestalozzi-tech.com/alpha-terms/"
              className="underline"
            >
              <span className="px-1">サービス利用規約</span>
            </Anchor>
            および、
            <Anchor
              href="https://pestalozzi-tech.com/privacy-policy/"
              className="underline"
            >
              <span className="px-1">個人情報保護ポリシー</span>
            </Anchor>
            に同意します。
          </p>
        </div>
        <div className="absolute welcome-button-wrapper bottom-6">
          <Button
            className="rounded-5px welcome-button"
            onClick={handleSignInBtn}
          >
            はじめる
          </Button>
        </div>

        <div className="absolute right-3 bottom-2">
          <div className="flex flex-col justify-center items-center">
            <ISMSLogo/>
            <p className="text-black text-xxs">
              Copyright © Pestalozzi Technology {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default SignInPage
