import {Dashboard} from '../layout/Dashboard'

import Video from '../cards/Video'
import {DashboardNavGraphItem} from '../navGraph/DashboardNavGraphItem'
import { Row } from 'antd'
import { Card } from '~/alpha/components';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const adviseMovies = [
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/009_introduction/video/009_introduction_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/009_introduction.jpg',
    title: '右代啓祐選手からのメッセージ',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/005_grip_strength/video/005_grip_strength_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/005_grip_strength.jpg',
    title: '新体力テストのコツ「握力」',
    desc: '右代啓祐選手の得点はいくつだろう？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/007_sit-up/video/007_sit-up_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/007_sit-up.jpg',
    title: '新体力テストのコツ「上体起こし」',
    desc: 'どんな筋肉が強くなる？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/008_flexibility/video/008_flexibility_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/008_flexibility.jpg',
    title: '新体力テストのコツ「長座体前屈」',
    desc: 'ワンポイントアドバイスを見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/006_sidestep/video/006_sidestep_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/006_sidestep.jpg',
    title: '新体力テストのコツ「反復横跳び」',
    desc: '右代啓祐選手の得点はいくつだろう？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/001_50m/video/001_50m_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/001_50m.jpg',
    title: '新体力テストのコツ「50m走」',
    desc: 'どんな筋肉が強くなる？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/002_20m_shuttle_run/video/002_20m_shuttle_run_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/002_20m_shuttle_run.jpg',
    title: '新体力テストのコツ「20mシャトルラン」',
    desc: 'ワンポイントアドバイスを見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/003_broad_jump/video/003_broad_jump_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/003_broad_jump.jpg',
    title: '新体力テストのコツ「立ち幅跳び」',
    desc: '',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/004_throwing_ball/video/004_throwing_ball_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/004_throwing_ball.jpg',
    title: '新体力テストのコツ「ボール投げ」',
    desc: '',
  },
]

const cityVideos = [
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/01.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/01.png',
    title: '10パス',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/02.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/02.png',
    title: 'ぐらぐらキャッチ',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/03.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/03.png',
    title: 'ステップキャッチ',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/04.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/city-videos/04.png',
    title: 'パワーグリップ',
  }, 
]

const AdvisePage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const adviseTitle = <div className="theme-nav-title">
    お
    <ruby>
      手本<rt className="text-10px font-black">てほん</rt>
    </ruby>
    <ruby>
      動画<rt className="text-10px font-black">どうが</rt>
    </ruby>
  </div>
  const title = <div className="theme-nav-title">
    <ruby>
      動画<rt className="text-10px font-black">どうが</rt>を
    </ruby>
    <ruby>
      見<rt className="text-10px font-black">み</rt>る
    </ruby>
  </div>
  const cityTitle = <div className="theme-nav-title">
    <ruby>
      運動<rt className="text-10px font-black">うんどう</rt>
    </ruby>
    あそび
    <ruby>
      動画<rt className="text-10px font-black">どうが</rt>
    </ruby>
  </div>

  const history = useHistory()
  const location = useLocation();
  const search = location.search;
  const videoType = search.includes("city") ? 'city' : 'advise';

  return (
    <Dashboard
      selectedMenu={graphItem?.tabIndex}
      navbar={
        search.includes("city")
          ? cityTitle
          : search.includes("advise") ? adviseTitle : title
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      authenticated={false}
    >
      <Row justify="center" className="pt-5">
        <Row style={{width: 966, justifyContent: 'space-evenly', alignItems: 'center'}}>
          {search.includes('advise') && adviseMovies.map((m, index) => (
            <div className="w-1/3 pl-2" key={`advise-${index + 1}`}>
              <Video
                key={`advise-${index + 1}`}
                keyName={`${videoType}-${index + 1}`}
                src={m.url}
                size={{ width: 292, height: 164 }}
                thumb={m.thumb}
                className="rounded-10px overflow-hidden"
              />
              <Row><span className="mt-1 text-black">{m.title}</span></Row>
            </div>
          ))}
          {search.includes('city') && cityVideos.map((m, index) => (
            <div className="w-1/3 pl-2" key={`advise-${index + 1}`}>
              <Video
                key={`advise-${index + 1}`}
                keyName={`${videoType}-${index + 1}`}
                src={m.url}
                size={{ width: 292, height: 164 }}
                thumb={m.thumb}
                className="rounded-10px overflow-hidden"
              />
              <Row><span className="mt-1 text-black">{m.title}</span></Row>
            </div>
          ))}
          {search === '' && <>
            <Card
              onClick={() => history.push('/advise?type=advise')}
              style={{ width: 300, height: 300, textAlign: 'center', fontSize: '22px', paddingTop: '136px', cursor: 'pointer' }}
            >
              お手本動画
            </Card>
            <Card
              onClick={() => history.push('/advise?type=city')}
              style={{ width: 300, height: 300, textAlign: 'center', fontSize: '22px', paddingTop: '136px', cursor: 'pointer' }}
            >
              運動あそび動画
            </Card>
          </>}
        </Row>
      </Row>
    </Dashboard>
  )
}

export default AdvisePage
