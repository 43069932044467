import {Button} from 'antd'

import {useHistory} from "react-router-dom";
import {Dashboard} from "../layout/Dashboard";
import {DashboardNavGraphItem} from "../navGraph/DashboardNavGraphItem";
import { useStudentMeApiQuery } from '~/utils/hooks';

const MyPage = ({
                  graphItem,
                  isOpen,
                  setIsOpen,
                }: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const history = useHistory()
  const { data: studentData } = useStudentMeApiQuery()
  
  const title = <div className="theme-nav-title">{graphItem.title}</div>
  const studentAttr = studentData?.attributes

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customNavigate
      onClickNavigate={() => window.location.href = "https://kobe-kid-home.alpha-pestalozzi.com/home"}
    >
      <div className="h-full flex justify-center items-center overflow-hidden">
        <div style={{width: 800, height: 320}} className="bg-gray-150 pt-11 pb-5 rounded-10px">
          <div className="space-y-6">
            <div className="space-y-7 px-40 pr-20">
              <div className="flex items-baseline">
                <div style={{ fontWeight: 900 }} className="flex-1 text-left">
                  <ruby>ID</ruby>
                </div>
                <div className="text-lg text-center">
                  {studentData?.username}
                </div>
                <div className="flex-1"/>
              </div>
              <div className="flex">
                <div className="flex-1 text-left">
                  <ruby className="font-black">学校名
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  </ruby>
                </div>
                <div className="text-lg text-center">
                  {studentAttr?.schoolName}
                </div>
                <div className="flex-1"/>
              </div>
              <div className="flex">
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">学年
                    <rt className="text-xxs font-black">がくねん</rt>
                  </ruby>
                  <span className="text-lg">{studentAttr?.schoolGrade}</span>
                </div>
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">
                    組
                    <rt>くみ</rt>
                  </ruby>
                  <span className="text-lg">{studentAttr?.schoolClass}</span>
                </div>
                <div className="flex-1 text-left">
                  <ruby className="font-black mr-5">出席番号
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  </ruby>
                  <span className="text-lg">{studentAttr?.schoolAttendanceNumber}</span>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 80 }} className="space-x-4 flex items-center justify-center">
              <div>
                <div>
                  <Button
                    type="primary"
                    className="w-60"
                    size="large"
                    onClick={() => history.push('/logout')}
                  >
                    ログアウト
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default MyPage
