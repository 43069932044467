import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../../auth/AuthProvider'
import { Client } from '../../generated/graphql'
import OfflineLink from 'apollo-link-offline'
// import { persistCache } from 'apollo-cache-persist'
import axios from 'axios'

const cache = new InMemoryCache({})

const generateUuid = () => {
  let chars = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.split('')
  for (let i = 0, len = chars.length; i < len; i++) {
    switch (chars[i]) {
      case 'x':
        chars[i] = Math.floor(Math.random() * 16).toString(16)
        break
      case 'y':
        chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16)
        break
    }
  }
  return chars.join('')
}

const authLink = setContext((_, { headers }) => {
  const uuid = generateUuid()
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  axios.interceptors.request.use(
    function (config) {
      config.headers.common = {
        ...(config.headers.common as any),
        ...(authToken
          ? { [LOCAL_STORAGE_AUTH_TOKEN_KEY]: `Bearer ${authToken}` }
          : {}),
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )
  return {
    headers: {
      ...headers,
      'session-id': uuid,
      [LOCAL_STORAGE_AUTH_TOKEN_KEY]: `Bearer ${authToken}`,
    },
  }
})

class LocalStorage {
  static setItem(key: string, value: string | null) {
    const data = !value ? '' : value
    window.localStorage.setItem(key, data)
  }

  static getItem(key: string) {
    const item = window.localStorage.getItem(key)
    return Promise.resolve(item)
  }

  static removeItem(key: string) {
    window.localStorage.removeItem(key)
  }
}

export const offlineLink = new OfflineLink({
  storage: LocalStorage,
})

export async function getApolloClient(clientType: Client) {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    headers: {
      client: clientType,
    },
  })
  const client = new ApolloClient({
    link: ApolloLink.from([offlineLink, authLink, httpLink]),
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        optimisticResponse: true,
        errorPolicy: 'all',
      },
    },
  })
  // await persistCache({
  //   cache,
  //   storage: LocalStorage,
  //   maxSize: false,
  //   debug: true,
  // })
  await offlineLink.setup(client)
  return client
}
