import { Route, useHistory } from 'react-router-dom'
import { useContext, useEffect } from 'react'
import { AuthContext } from '../auth/AuthProvider'
import { findTokenUrl } from '../../../utils/string'

export const RedirectRoute = ({
  component: Component,
  ...rest
}) => {
  const auth = useContext(AuthContext)
  const { location: { search }, push } = useHistory()

  const isToken = search?.includes('?token=') && search?.includes('&redirectTo=')

  useEffect(() => {
    if (isToken) {
      const { token, redirectTo } = findTokenUrl(search)
      auth.setAccessToken(token)
      push(redirectTo)
    }
  }, [search])

  return (
    <Route
      {...rest}
      render={() => (
        <div>Loading...</div>
      )}
    />
  )
}
