import {Col, Row} from "antd";

export const DEFAULT = [
  {
    "type": "elementary",
    "id": "q1",
    "question": <>
      Q1 :
      <ruby className="font-black">運動部
        <rt className="text-xxs font-normal">うんどうぶ</rt>
      </ruby>
      やスポーツクラブに
      <ruby className="font-black">入
        <rt className="text-xxs font-normal">はい</rt>
      </ruby>
      っていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            はいっている
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            はいっていない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q2",
    "question": <>
      Q２ :
      <ruby className="font-black">運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      ・スポーツはどのくらいしていますか？
    </>,
    "options": {
      1: (
        <>ほとんど
          <ruby>
            毎日
            <rt className="text-xxs font-normal">まいにち</rt>
          </ruby>
          （
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          3
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>

          ）
        </>
      ),
      2: (
        <>ときどき（
          <ruby>
            週1
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          〜
          <ruby>
            2日程度
            <rt className="text-xxs font-normal">にちていど</rt>
          </ruby>
          ）
        </>
      ),
      3: (
        <>ときたま（
          <ruby>
            月1
            <rt className="text-xxs font-normal">つき</rt>
          </ruby>
          〜
          <ruby>
            2日程度
            <rt className="text-xxs font-normal">にちていど</rt>
          </ruby>
          ）
        </>
      ),
      4: (
        <>
          <ruby>
            しない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q3",
    "question": (
      <>Q３ :
        <ruby>
          運動
          <rt className="text-xxs font-normal">うんどう</rt>
        </ruby>
        やスポーツをするときは1
        <ruby>
          日
          <rt className="text-xxs font-normal">にち</rt>
        </ruby>
        にどのくらいの
        <ruby>
          時間
          <rt className="text-xxs font-normal">じかん</rt>
        </ruby>
        しますか？（
        <ruby>
          学校
          <rt className="text-xxs font-normal">がっこう</rt>
        </ruby>
        の
        <ruby>
          体育
          <rt className="text-xxs font-normal">たいいく</rt>
        </ruby>
        の
        <ruby>
          授業
          <rt className="text-xxs font-normal">じゅぎょう</rt>
        </ruby>
        をのぞきます）
      </>
    ),
    "options": {
      1: (
        <>30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q4",
    "question": (
      <>
        Q４ :
        <ruby>
          小学校入学前はど
          <rt className="text-xxs font-normal">しょうがっこうにゅうがくまえ</rt>
        </ruby>
        のくらい
        <ruby>
          外
          <rt className="text-xxs font-normal">そと</rt>
        </ruby>
        で
        <ruby>
          体
          <rt className="text-xxs font-normal">からだ</rt>
        </ruby>
        を
        <ruby>
          動か
          <rt className="text-xxs font-normal">うご</rt>
        </ruby>
        す
        <ruby>
          遊
          <rt className="text-xxs font-normal">あそ</rt>
        </ruby>
        びをしていましたか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          6
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
          以上
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に4〜5
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に2〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1日
          <ruby>
            以下
            <rt className="text-xxs font-normal">いか</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q5",
    "question": (
      <>
        Q5 :
        <ruby>
          朝食
          <rt className="text-xxs font-normal">ちょうしょく</rt>
        </ruby>
        は
        <ruby>
          食
          <rt className="text-xxs font-normal">た</rt>
        </ruby>
        べますか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            毎日食
            <rt className="text-xxs font-normal">まいにちた</rt>
          </ruby>
          べる
        </>
      ),
      2: (
        <>
          <ruby>
            時々
            <rt className="text-xxs font-normal">ときどき</rt>
          </ruby>
          <ruby>
            食べ
            <rt className="text-xxs font-normal">たべ</rt>
          </ruby>
          ない
        </>
      ),
      3: (
        <>
          <ruby>
            毎日
            <rt className="text-xxs font-normal">まいにち</rt>
          </ruby>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q6",
    "question": (
      <>
        Q6 : 1
        <ruby>
          日
          <rt className="text-xxs font-normal">にち</rt>
        </ruby>
        の
        <ruby>
          睡眠時間
          <rt className="text-xxs font-normal">すいみんじかん</rt>
        </ruby>
      </>
    ),
    "options": {
      1: (
        <>
          6
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          6
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          8
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          8
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q7",
    "question": (
      <>
        Q7 : ぐっすり
        <ruby>
          眠
          <rt className="text-xxs font-normal">ねむ</rt>
        </ruby>
        る（よく
        <ruby>
          眠
          <rt className="text-xxs font-normal">ねむ</rt>
        </ruby>
        る）ことが
        <ruby>
          出来
          <rt className="text-xxs font-normal">でき</rt>
        </ruby>
        ますか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          6日以上
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に4〜5
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に2〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1日
          <ruby>
            以下
            <rt className="text-xxs font-normal">いか</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q8",
    "question": (
      <>
        Q8 : 1
        <ruby>
          日
          <rt className="text-xxs font-normal">にち</rt>
        </ruby>
        にどのくらいテレビを見ますか？（テレビゲーム含む）
      </>
    ),
    "options": {
      1: (
        <>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          3
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          3
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q1",
    "question": "Q1 : 運動部やスポーツクラブへの所属状況",
    "options": {
      1: (
        <>
          <ruby>
            所属している
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            所属していない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q2",
    "question": "Q２ : 運動・スポーツの実施状況（学校の体育の授業を除く）",
    "options": {
      1: (
        <>
          <ruby>
            ほとんど毎日（週3日以上）
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            ときどき（週1〜2日程度）
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            ときたま（月1〜2日程度）
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            しない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q3",
    "question": "Q３ : 1日の運動・スポーツ実施時間（学校の体育の授業をのぞきます）",
    "options": {
      1: (
        <>
          <ruby>
            30分未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            30分以上1時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            1時間以上2時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            2時間以上
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q4",
    "question": "Q4 :朝食の有無",
    "options": {
      1: (
        <>
          <ruby>
            毎日食べる
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            時々欠かす
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            毎日食べない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q5",
    "question": "Q5 : ぐっすり眠る（よく眠る）ことが出来ますか？",
    "options": {
      1: (
        <>
          <ruby>
            週6日以上
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週に4〜5日
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週に2〜3日
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週に1日以下
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q6",
    "question": "Q6 : 1日の睡眠時間 ",
    "options": {
      1: (
        <>
          <ruby>
            6時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            6時間以上8時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            8時間以上
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q7",
    "question": "Q7: 1日のテレビ（テレビゲーム含む）の視聴時間",
    "options": {
      1: (
        <>
          <ruby>
            1時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            1時間以上2時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            2時間以上3時間未満
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            3時間以上
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q8",
    "question": "Q8: なんでも最後までやり遂げたいと思う",
    "options": {
      1: (
        <>
          <ruby>
            とてもそう思う
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            まあそう思う
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            あまりそう思わない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            まったくそう思わない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q9",
    "question": "Q9 : 学校内で所属している部活動",
    "options": [
      "サッカー",
      "野球",
      "軟式野球",
      "バスケットボール",
      "バレーボール",
      "テニス",
      "ソフトテニス",
      "バドミントン",
      "卓球",
      "陸上",
      "ハンドボール",
      "剣道",
      "柔道",
      "空手",
      "弓道",
      "水泳",
      "ソフトボール",
      "体操",
      "新体操",
      "アメリカンフットボール",
      "ラグビー",
      "スキー",
      "スケート",
      "相撲",
      "その他",
      "所属していない"
    ]
  }
]

export const TOYAMA = [
  {
    "type": "elementary",
    "id": "q1",
    "question": <>
      Q1 : スポ
      <ruby className="font-black">
        少
        <rt className="text-xxs font-normal">しょう</rt>
      </ruby>
      ・クラブに
      <ruby className="font-black">
        所属
        <rt className="text-xxs font-normal">しょぞく</rt>
      </ruby>
      しているか
    </>,
    "options": {
      1: (
        <>
          <ruby>
            所属
            <rt className="text-xxs font-normal">しょぞく</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            無所属
            <rt className="text-xxs font-normal">むしょぞく</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q2",
    "question": <>
      Q２ :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      の
      <ruby className="font-black">
        実施状況
        <rt className="text-xxs font-normal">じっしじょうきょう</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          3
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          1~2
          <ruby>
            日程度
            <rt className="text-xxs font-normal">にちていど</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            月
            <rt className="text-xxs font-normal">つき</rt>
          </ruby>
          1〜3
          <ruby>
            日程度
            <rt className="text-xxs font-normal">にちていど</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            しない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q3",
    "question": <>
      Q３ : 1
      <ruby className="font-black">
        日
        <rt className="text-xxs font-normal">にち</rt>
      </ruby>
      の
      <ruby className="font-black">
        運動時間
        <rt className="text-xxs font-normal">うんどうじかん</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q4",
    "question": <>
      Q４ :
      <ruby className="font-black">
        小学校入学前
        <rt className="text-xxs font-normal">しょうがっこうにゅうがくまえ</rt>
      </ruby>
      の
      <ruby className="font-black">
        外
        <rt className="text-xxs font-normal">そと</rt>
      </ruby>
      での
      <ruby className="font-black">
        遊
        <rt className="text-xxs font-normal">あそ</rt>
      </ruby>
      びの
      <ruby className="font-black">
        日数
        <rt className="text-xxs font-normal">にっすう</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          6
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に4〜5
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に2〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1
          <ruby>
            日以下
            <rt className="text-xxs font-normal">にちいか</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q5",
    "question": <>
      Q5 :
      <ruby className="font-black">
        朝食
        <rt className="text-xxs font-normal">ちょうしょく</rt>
      </ruby>
      の
      <ruby className="font-black">
        有無
        <rt className="text-xxs font-normal">うむ</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          <ruby>
            毎日食
            <rt className="text-xxs font-normal">まいにちた</rt>
          </ruby>
          べる
        </>
      ),
      2: (
        <>
          <ruby>
            時々食
            <rt className="text-xxs font-normal">ときどきた</rt>
          </ruby>
          べない
        </>
      ),
      3: (
        <>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q6",
    "question": <>
      Q6 :
      <ruby className="font-black">
        睡眠時間
        <rt className="text-xxs font-normal">すいみんじかん</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          6
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          6
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          〜8
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          8
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q7",
    "question": <>
      Q7 :ぐっすり
      <ruby className="font-black">
        眠
        <rt className="text-xxs font-normal">ねむ</rt>
      </ruby>
      れる
      <ruby className="font-black">
        日数
        <rt className="text-xxs font-normal">にっすう</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          6
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に4〜5
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に2〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1
          <ruby>
            日以下
            <rt className="text-xxs font-normal">にちいか</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q8",
    "question": <>
      Q8 : テレビの
      <ruby className="font-black">
        視聴時間
        <rt className="text-xxs font-normal">しちょうじかん</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          3
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          3
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q9",
    "question": <>
      Q９ :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      の
      <ruby className="font-black">
        得意
        <rt className="text-xxs font-normal">とくい</rt>
      </ruby>
    </>,
    "options": {
      1: (
        <>
          <ruby>
            得意
            <rt className="text-xxs font-normal">とくい</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            得意
            <rt className="text-xxs font-normal">とくい</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            得意
            <rt className="text-xxs font-normal">とくい</rt>
          </ruby>
          ではない
        </>
      ),
      4: (
        <>
          <ruby>
            得意
            <rt className="text-xxs font-normal">とくい</rt>
          </ruby>
          ではない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q10",
    "question": <>
      Q10 :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      の
      <ruby className="font-black">
        好
        <rt className="text-xxs font-normal">す</rt>
      </ruby>
      き・
      <ruby className="font-black">
        嫌
        <rt className="text-xxs font-normal">きら</rt>
      </ruby>
      い
    </>,
    "options": {
      1: (
        <>
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          き
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          き
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          きではない
        </>
      ),
      4: (
        <>
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          きではない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q11",
    "question": <>
      Q11 :
      <ruby className="font-black">
        体育
        <rt className="text-xxs font-normal">たいいく</rt>
      </ruby>
      は
      <ruby className="font-black">
        楽
        <rt className="text-xxs font-normal">たの</rt>
      </ruby>
      しい
    </>,
    "options": {
      1: (
        <>
          <ruby>
            楽
            <rt className="text-xxs font-normal">たの</rt>
          </ruby>
          しい
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            楽
            <rt className="text-xxs font-normal">たの</rt>
          </ruby>
          しい
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            楽
            <rt className="text-xxs font-normal">たの</rt>
          </ruby>
          しくない
        </>
      ),
      4: (
        <>
          <ruby>
            楽
            <rt className="text-xxs font-normal">たの</rt>
          </ruby>
          しくない
        </>
      )
    }
  }
]

export const MIYAZAKI = [
  {
    "type": "elementary",
    "id": "q1",
    "question": <>
      Q1 :
      <ruby className="font-black">運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      <ruby className="font-black">部
        <rt className="text-xxs font-normal">ぶ</rt>
      </ruby>
      やスポーツクラブに
      <ruby className="font-black">入
        <rt className="text-xxs font-normal">はい</rt>
      </ruby>
      っていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            はいっている
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            はいっていない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q2",
    "question": <>
      Q２ :
      <ruby className="font-black">運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      ・スポーツはどのくらいしていますか？（
      <ruby>
        学校
        <rt className="text-xxs font-normal">がっこう</rt>
      </ruby>
      の
      <ruby>
        体育
        <rt className="text-xxs font-normal">たいいく</rt>
      </ruby>
      の
      <ruby>
        授業
        <rt className="text-xxs font-normal">じゅぎょう</rt>
      </ruby>
      をのぞきます）
    </>,
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          3
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          1〜2
          <ruby>
            日程度
            <rt className="text-xxs font-normal">にちていど</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            月
            <rt className="text-xxs font-normal">つき</rt>
          </ruby>
          1〜3
          <ruby>
            日程度
            <rt className="text-xxs font-normal">にちていど</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            しない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q3",
    "question": (
      <>Q３ :
        <ruby>
          運動
          <rt className="text-xxs font-normal">うんどう</rt>
        </ruby>
        やスポーツをするときは1
        <ruby>
          日
          <rt className="text-xxs font-normal">にち</rt>
        </ruby>
        にどのくらいの
        <ruby>
          時間
          <rt className="text-xxs font-normal">じかん</rt>
        </ruby>
        しますか？（
        <ruby>
          学校
          <rt className="text-xxs font-normal">がっこう</rt>
        </ruby>
        の
        <ruby>
          体育
          <rt className="text-xxs font-normal">たいいく</rt>
        </ruby>
        の
        <ruby>
          授業
          <rt className="text-xxs font-normal">じゅぎょう</rt>
        </ruby>
        をのぞきます）
      </>
    ),
    "options": {
      1: (
        <>30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q4",
    "question": (
      <>
        Q4 :
        <ruby>
          朝食
          <rt className="text-xxs font-normal">ちょうしょく</rt>
        </ruby>
        は
        <ruby>
          食
          <rt className="text-xxs font-normal">た</rt>
        </ruby>
        べますか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            毎日食
            <rt className="text-xxs font-normal">まいにちた</rt>
          </ruby>
          べる
        </>
      ),
      2: (
        <>
          <ruby>
            時々
            <rt className="text-xxs font-normal">ときどき</rt>
          </ruby>
          <ruby>
            食べ
            <rt className="text-xxs font-normal">たべ</rt>
          </ruby>
          ない
        </>
      ),
      3: (
        <>
          <ruby>
            毎日
            <rt className="text-xxs font-normal">まいにち</rt>
          </ruby>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q5",
    "question": (
      <>
        Q5 : 1
        <ruby>
          日
          <rt className="text-xxs font-normal">にち</rt>
        </ruby>
        の
        <ruby>
          睡眠時間
          <rt className="text-xxs font-normal">すいみんじかん</rt>
        </ruby>
        はどのくらいですか？
      </>
    ),
    "options": {
      1: (
        <>
          6
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          6
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          8
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          8
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q6",
    "question": (
      <>
        Q6 :
        <ruby>
          平日
          <rt className="text-xxs font-normal">へいじつ</rt>
        </ruby>
        の
        <ruby>
          就寝時間
          <rt className="text-xxs font-normal">しゅうしんじかん</rt>
        </ruby>
        は
        <ruby>
          何時
          <rt className="text-xxs font-normal">なんじ</rt>
        </ruby>
        ですか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            午後
            <rt className="text-xxs font-normal">ごご</rt>
          </ruby>
          9
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          <ruby>
            前
            <rt className="text-xxs font-normal">まえ</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            午後
            <rt className="text-xxs font-normal">ごご</rt>
          </ruby>
          9
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          から
          <ruby>
            午前
            <rt className="text-xxs font-normal">ごぜん</rt>
          </ruby>
          10
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          <ruby>
            前
            <rt className="text-xxs font-normal">まえ</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            午後
            <rt className="text-xxs font-normal">ごご</rt>
          </ruby>
          10
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          から
          <ruby>
            午前
            <rt className="text-xxs font-normal">ごぜん</rt>
          </ruby>
          11
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          <ruby>
            前
            <rt className="text-xxs font-normal">まえ</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            午後
            <rt className="text-xxs font-normal">ごご</rt>
          </ruby>
          11
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          から
          <ruby>
            午前
            <rt className="text-xxs font-normal">ごぜん</rt>
          </ruby>
          0
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          <ruby>
            前
            <rt className="text-xxs font-normal">まえ</rt>
          </ruby>
        </>
      ),
      5: (
        <>
          <ruby>
            午後
            <rt className="text-xxs font-normal">ごご</rt>
          </ruby>
          0
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          から
          <ruby>
            午前
            <rt className="text-xxs font-normal">ごぜん</rt>
          </ruby>
          1
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          <ruby>
            前
            <rt className="text-xxs font-normal">まえ</rt>
          </ruby>
        </>
      ),
      6: (
        <>
          <ruby>
            午前
            <rt className="text-xxs font-normal">ごぜん</rt>
          </ruby>
          1
          <ruby>
            時
            <rt className="text-xxs font-normal">じ</rt>
          </ruby>
          <ruby>
            以降
            <rt className="text-xxs font-normal">いこう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q7",
    "question": (
      <table>
        <tr>
          <td>
            <tr>Q7 :</tr>
            <tr>&nbsp;</tr>
          </td>
          <td>
            <tr>
              1
              <ruby>
                日
                <rt className="text-xxs font-normal">にち</rt>
              </ruby>
              にどれくらいの
              <ruby>
                時間
                <rt className="text-xxs font-normal">じかん</rt>
              </ruby>
              、テレビやビデオ・DVDを
              <ruby>
                見
                <rt className="text-xxs font-normal">み</rt>
              </ruby>
              たり、テレビゲームをしたりしますか？
            </tr>
            <tr>
              （コンピューターや
              <ruby>
                携帯電話
                <rt className="text-xxs font-normal">けいたいでんわ</rt>
              </ruby>
              ・スマートフォン
              <ruby>
                等
                <rt className="text-xxs font-normal">とう</rt>
              </ruby>
              でのゲーム
              <ruby>
                時間
                <rt className="text-xxs font-normal">じかん</rt>
              </ruby>
              も
              <ruby>
                含
                <rt className="text-xxs font-normal">ふく</rt>
              </ruby>
              みます）
            </tr>
          </td>
        </tr>
      </table>
    ),
    "options": {
      1: (
        <>
          1
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            未満
            <rt className="text-xxs font-normal">みまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          1
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            以上
            <rt className="text-xxs font-normal">いじょう</rt>
          </ruby>
          2
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            未満
            <rt className="text-xxs font-normal">みまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          2
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            以上
            <rt className="text-xxs font-normal">いじょう</rt>
          </ruby>
          3
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            未満
            <rt className="text-xxs font-normal">みまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          3
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            以上
            <rt className="text-xxs font-normal">いじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q8",
    "question": (
      <table>
        <tr>
          <td>
            <tr>Q8 :</tr>
            <tr>&nbsp;</tr>
          </td>
          <td>
            <tr>
              1
              <ruby>
                日
                <rt className="text-xxs font-normal">にち</rt>
              </ruby>
              にどれくらいの
              <ruby>
                時間
                <rt className="text-xxs font-normal">じかん</rt>
              </ruby>
              、
              <ruby>
                携帯電話
                <rt className="text-xxs font-normal">けいたいでんわ</rt>
              </ruby>
              やスマートフォンで、
              <ruby>
                通話
                <rt className="text-xxs font-normal">つうわ</rt>
              </ruby>
              やメール、
              <ruby>
                及
                <rt className="text-xxs font-normal">およ</rt>
              </ruby>
              びインターネットをしますか。
            </tr>
            <tr>
              （
              <ruby>
                携帯電話
                <rt className="text-xxs font-normal">けいたいでんわ</rt>
              </ruby>
              ・スマートフォンを
              <ruby>
                使
                <rt className="text-xxs font-normal">つか</rt>
              </ruby>
              ってゲームをする
              <ruby>
                時間
                <rt className="text-xxs font-normal">じかん</rt>
              </ruby>
              は
              <ruby>
                除
                <rt className="text-xxs font-normal">のぞ</rt>
              </ruby>
              く）
            </tr>
          </td>
        </tr>
      </table>
    ),
    "options": {
      1: (
        <>
          <ruby>
            使用
            <rt className="text-xxs font-normal">しよう</rt>
          </ruby>
          しない
        </>
      ),
      2: (
        <>
          1
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            未満
            <rt className="text-xxs font-normal">みまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          1
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            以上
            <rt className="text-xxs font-normal">いじょう</rt>
          </ruby>
          2
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            未満
            <rt className="text-xxs font-normal">みまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          2
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            以上
            <rt className="text-xxs font-normal">いじょう</rt>
          </ruby>
          3
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            未満
            <rt className="text-xxs font-normal">みまん</rt>
          </ruby>
        </>
      ),
      5: (
        <>
          3
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          <ruby>
            以上
            <rt className="text-xxs font-normal">いじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q9",
    "question": (
      <>
        Q9 :どのような
        <ruby>
          交通手段
          <rt className="text-xxs font-normal">こうつうしゅだん</rt>
        </ruby>
        で
        <ruby>
          通学
          <rt className="text-xxs font-normal">つうがく</rt>
        </ruby>
        していますか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            徒歩
            <rt className="text-xxs font-normal">とほ</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          バスや
          <ruby>
            電車
            <rt className="text-xxs font-normal">でんしゃ</rt>
          </ruby>
          などの
          <ruby>
            公共交通機関
            <rt className="text-xxs font-normal">こうきょうこうつうきかん</rt>
          </ruby>
          <br/>
        </>
      ),
      3: (
        <>
          <ruby>
            自家用車
            <rt className="text-xxs font-normal">じかようしゃ</rt>
          </ruby>
          ・バイク（
          <ruby>
            家族等
            <rt className="text-xxs font-normal">かぞくとう</rt>
          </ruby>
          の
          <ruby>
            送迎
            <rt className="text-xxs font-normal">そうげい</rt>
          </ruby>
          ）
        </>
      ),
      4: (
        <>
          その
          <ruby>
            他
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q10-1",
    "question": (
      <>
        Q10-1 :
        <ruby>
          外遊び
          <rt className="text-xxs font-normal">そとあそび</rt>
        </ruby>
        をどのくらいしていますか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に3
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1~2
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
          ぐらい
        </>
      ),
      3: (
        <>
          <ruby>
            月
            <rt className="text-xxs font-normal">つき</rt>
          </ruby>
          に1~3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
          ぐらい
        </>
      ),
      4: (
        <>
          <ruby>
            しない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q10-2",
    "question": (
      <>
        Q10-2 : 1〜３と
        <ruby>
          答
          <rt className="text-xxs font-normal">こた</rt>
        </ruby>
        えた
        <ruby>
          人
          <rt className="text-xxs font-normal">ひと</rt>
        </ruby>
        は、どのような
        <ruby>
          時間
          <rt className="text-xxs font-normal">じかん</rt>
        </ruby>
        に
        <ruby>
          外遊
          <rt className="text-xxs font-normal">そとあそ</rt>
        </ruby>
        びを
        <ruby>
          行
          <rt className="text-xxs font-normal">おこな</rt>
        </ruby>
        いますか？
      </>
    ),
    "options": {
      1: (
        <>
          <ruby>
            始業前
            <rt className="text-xxs font-normal">しぎょうまえ</rt>
          </ruby>
          （
          <ruby>
            登校後
            <rt className="text-xxs font-normal">とうこうご</rt>
          </ruby>
          ）
        </>
      ),
      2: (
        <>
          <ruby>
            昼休み
            <rt className="text-xxs font-normal">ひるやす</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            放課後
            <rt className="text-xxs font-normal">ほうかご</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            下校後
            <rt className="text-xxs font-normal">げこくご</rt>
          </ruby>
          （
          <ruby>
            帰宅
            <rt className="text-xxs font-normal">きたく</rt>
          </ruby>
          したあと）
        </>
      ),
      5: (
        <>
          <ruby>
            回答
            <rt className="text-xxs font-normal">かいとう</rt>
          </ruby>
          なし
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q11",
    "question": (
      <>
        Q11 :
        <ruby>
          体育
          <rt className="text-xxs font-normal">たいいく</rt>
        </ruby>
        の
        <ruby>
          授業
          <rt className="text-xxs font-normal">じゅぎょう</rt>
        </ruby>
        は
        <ruby>
          好
          <rt className="text-xxs font-normal">す</rt>
        </ruby>
        きですか？
      </>
    ),
    "options": {
      1: (
        <>
          たいへん
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          き
        </>
      ),
      2: (
        <>
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          き
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
          </ruby>
          きでない
        </>
      ),
      4: (
        <>
          <ruby>
            きらい
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q12",
    "question": (
      <table>
        <tr>
          <td>
            <tr>Q12 :</tr>
            <tr>&nbsp;</tr>
          </td>
          <td>
            <tr>
              <ruby>
                一週間
                <rt className="text-xxs font-normal">いっしゅうかん</rt>
              </ruby>
              にどれくらいの
              <ruby>
                時間
                <rt className="text-xxs font-normal">じかん</rt>
              </ruby>
              、
              <ruby>
                保護者
                <rt className="text-xxs font-normal">ほごしゃ</rt>
              </ruby>
              と
              <ruby>
                一緒
                <rt className="text-xxs font-normal">いっしょ</rt>
              </ruby>
              に
              <ruby className="font-black">
                運動
                <rt className="text-xxs font-normal">うんどう</rt>
              </ruby>
              やスポーツしたり、
              <ruby className="font-black">
                運動
                <rt className="text-xxs font-normal">うんどう</rt>
              </ruby>
              やスポーツの
              <ruby className="font-black">
                会話
                <rt className="text-xxs font-normal">かいわ</rt>
              </ruby>
              をしたり、
            </tr>
            <tr>
              スポーツ
              <ruby className="font-black">
                観戦
                <rt className="text-xxs font-normal">かんせん</rt>
              </ruby>
              （テレビも
              <ruby className="font-black">
                含
                <rt className="text-xxs font-normal">ふく</rt>
              </ruby>
              む）をしたりしていますか？（
              <ruby className="font-black">
                運動部
                <rt className="text-tiny font-normal">うんどうぶ</rt>
              </ruby>
              やスポーツクラブへの
              <ruby className="font-black">
                送迎時間
                <rt className="text-tiny font-normal">そうげいじかん</rt>
              </ruby>
              は
              <ruby className="font-black">
                含
                <rt className="text-xxs font-normal">ふく</rt>
              </ruby>
              まない）
            </tr>
          </td>
        </tr>
      </table>
    ),
    "options": {
      1: (
        <>
          30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          1
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          1
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      5: (
        <>
          2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      6: (
        <>
          2
          <ruby>
            時間
            <rt className="text-xxs font-normal">じかん</rt>
          </ruby>
          30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q13",
    "question": (
      <table>
        <tr>
          <td>
            <tr>Q13:</tr>
            <tr>&nbsp;</tr>
          </td>
          <td>
            <tr className="whitespace-nowrap">
              <ruby>
                宮崎県
                <rt className="text-xxs font-normal">みやざきけん</rt>
              </ruby>
              が
              <ruby>
                取
                <rt className="text-xxs font-normal">と</rt>
              </ruby>
              り
              <ruby>
                組
                <rt className="text-xxs font-normal">く</rt>
              </ruby>
              んでいる「みんながスポーツ1130
              <ruby>
                県民運動
                <rt className="text-xxs font-normal">けんみんうんどう</rt>
              </ruby>
              」（1
              <ruby>
                週間
                <rt className="text-xxs font-normal">しゅうかん</rt>
              </ruby>
              に1
              <ruby>
                回以上
                <rt className="text-xxs font-normal">かいいじょう</rt>
              </ruby>
              、30
              <ruby>
                分以上
                <rt className="text-xxs font-normal">ふんいじょう</rt>
              </ruby>
              は
              <ruby>
                運動
                <rt className="text-xxs font-normal">うんどう</rt>
              </ruby>
              ・スポーツをしよう）
            </tr>
            <tr>
              について
              <ruby>
                知
                <rt className="text-xxs font-normal">し</rt>
              </ruby>
              っていますか？
            </tr>
          </td>
        </tr>
      </table>
    ),
    "options": {
      1: (
        <>
          <ruby>
            知
            <rt className="text-xxs font-normal">し</rt>
          </ruby>
          っている
        </>
      ),
      2: (
        <>
          <ruby>
            聞
            <rt className="text-xxs font-normal">き</rt>
          </ruby>
          いたことはある
        </>
      ),
      3: (
        <>
          <ruby>
            全
            <rt className="text-xxs font-normal">まった</rt>
          </ruby>
          く
          <ruby>
            知
            <rt className="text-xxs font-normal">し</rt>
          </ruby>
          らない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q1",
    "question": (
      <>
        Q1 : 運動部やスポーツクラブに入ってますか？
      </>
    ),
    "options": {
      1: (
        <>
          入っている
        </>
      ),
      2: (
        <>
          入っていない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q2",
    "question": (
      <>
        Q2 : 運動・スポーツはどのくらいしていますか？（学校の体育の授業を除く）
      </>
    ),
    "options": {
      1: (
        <>
          週3日以上
        </>
      ),
      2: (
        <>
          週1~2日ぐらい
        </>
      ),
      3: (
        <>
          月1〜3日ぐらい
        </>
      ),
      4: (
        <>
          しない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q3",
    "question": (
      <>
        Q3 : 運動やスポーツをするときは１日どのくらいですか？（学校の体育の授業をのぞきます）
      </>
    ),
    "options": {
      1: (
        <>
          30分未満
        </>
      ),
      2: (
        <>
          30分以上1時間未満
        </>
      ),
      3: (
        <>
          1時間以上2時間未満
        </>
      ),
      4: (
        <>
          2時間以上
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q4",
    "question": (
      <>
        Q4 :朝食は食べますか？
      </>
    ),
    "options": {
      1: (
        <>
          毎日食べる
        </>
      ),
      2: (
        <>
          時々食べない
        </>
      ),
      3: (
        <>
          毎日食べない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q5",
    "question": (
      <>
        Q5 : 1日の睡眠時間はどれくらいですか？
      </>
    ),
    "options": {
      1: (
        <>
          6時間未満
        </>
      ),
      2: (
        <>
          6時間以上8時間未満
        </>
      ),
      3: (
        <>
          8時間以上
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q6",
    "question": (
      <>
        Q6 : 平均の就寝時間は何時ですか？
      </>
    ),
    "options": {
      1: (
        <>
          午後9時前
        </>
      ),
      2: (
        <>
          午後9時から10時前
        </>
      ),
      3: (
        <>
          午後10時から11時前
        </>
      ),
      4: (
        <>
          午後11時から午前0時前
        </>
      ),
      5: (
        <>
          午前0時から午前1時前
        </>
      ),
      6: (
        <>
          午前1時以降
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q7",
    "question": (
      <Row gutter={4}>
        <Col>
          Q7 :
        </Col>
        <Col>
          <Row>
            1日どれくらいの時間、テレビやビデオ・DVDを見たり、テレビゲームをしたりしますか？
          </Row>
          <Row>
            （コンピュータや携帯電話・スマートフォン等でのゲームの時間も含みます）
          </Row>
        </Col>
      </Row>
    ),
    "options": {
      1: (
        <>
          1時間未満
        </>
      ),
      2: (
        <>
          1時間以上2時間未満
        </>
      ),
      3: (
        <>
          2時間以上3時間未満
        </>
      ),
      4: (
        <>
          3時間以上
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q8",
    "question": (
      <Row gutter={4}>
        <Col>
          Q8 :
        </Col>
        <Col>
          <Row>
            1日にどれくらいの時間、携帯電話やスマートフォンで通話やメール、及びインターネットをしますか？
          </Row>
          <Row>
            （携帯電話やスマートフォンを使ってゲームをする時間は除く）
          </Row>
        </Col>
      </Row>
    ),
    "options": {
      1: (
        <>
          とてもそう思う
        </>
      ),
      2: (
        <>
          まあそう思う
        </>
      ),
      3: (
        <>
          あまりそう思わない
        </>
      ),
      4: (
        <>
          まったくそう思わない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q9",
    "question": (
      <>
        Q9 : どのような交通手段で通学していますか？
      </>
    ),
    "options": {
      1: (
        <>
          徒歩
        </>
      ),
      2: (
        <>
          バスや電車などの公共交通機関
        </>
      ),
      3: (
        <>
          自家用車・バイク（家族等の送迎）
        </>
      ),
      4: (
        <>
          自転車
        </>
      ),
      5: (
        <>
          バイク（高校生のみ）
        </>
      ),
      6: (
        <>
          その他
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q10",
    "question": (
      <>
        Q10 : 体育の授業は好きですか？
      </>
    ),
    "options": {
      1: (
        <>
          たいへん好き
        </>
      ),
      2: (
        <>
          好き
        </>
      ),
      3: (
        <>
          あまり好きではない
        </>
      ),
      4: (
        <>
          きらい
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q11",
    "question": (
      <>
        Q11 : 体力は必要だと思いますか？
      </>
    ),
    "options": {
      1: (
        <>
          とても必要だと思う
        </>
      ),
      2: (
        <>
          必要だと思う
        </>
      ),
      3: (
        <>
          あまり必要だと思わない
        </>
      ),
      4: (
        <>
          必要だとは思わない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q12",
    "question": (
      <Row gutter={4}>
        <Col>
          Q12 :
        </Col>
        <Col>
          <Row>
            一週間にどれくらいの時間、保護者と一緒に運動やスポーツをしたり、運動やスポーツの会話をしたり、
          </Row>
          <Row>
            スポーツ観戦（テレビも含む）をしたりしていますか。（運動部やスポーツクラブへの送迎時間は含まない）
          </Row>
        </Col>
      </Row>
    ),
    "options": {
      1: (
        <>
          30分未満
        </>
      ),
      2: (
        <>
          30分以上1時間未満
        </>
      ),
      3: (
        <>
          1時間以上1時間30分未満
        </>
      ),
      4: (
        <>
          1時間30分以上2時間未満
        </>
      ),
      5: (
        <>
          2時間以上2時間30分未満
        </>
      ),
      6: (
        <>
          2時間30分以上
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q13",
    "question": (
      <span className="whitespace-nowrap">
        Q13 : 宮崎県が取り組んでいる「みんながスポーツ1130県民運動」（1週間に1回以上、30分以上は運動・スポーツをしよう）について知っていますか？
      </span>
    ),
    "options": {
      1: (
        <>
          知っている
        </>
      ),
      2: (
        <>
          聞いたことはある
        </>
      ),
      3: (
        <>
          全く知らない
        </>
      )
    }
  },
]

export const TOCHIGI = [
  {
    "type": "elementary",
    "id": "q1",
    "question": <>
      Q1 :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      やスポーツをすることは
      <ruby className="font-black">
        好
        <rt className="text-xxs font-normal">す</rt>
      </ruby>
      きですか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
            き
          </ruby>
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
            き
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            ややきらい
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            きらい
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q2",
    "question": <>
      Q2 :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      やスポーツをすることは
      <ruby className="font-black">
        得意
        <rt className="text-xxs font-normal">とくい</rt>
      </ruby>
      ですか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            得意
            <rt className="text-xxs font-normal">とくい</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            得意
            <rt className="text-xxs font-normal">とくい</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          やや
          <ruby>
            不得意
            <rt className="text-xxs font-normal">ふとくい</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            不得意
            <rt className="text-xxs font-normal">ふとくい</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q3",
    "question": <>
      Q3 :
      <ruby className="font-black">
        運動部
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      やスポーツクラブに
      <ruby className="font-black">
        入
        <rt className="text-xxs font-normal">はい</rt>
      </ruby>
      っていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            入
            <rt className="text-xxs font-normal">はい</rt>
            っている
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            入
            <rt className="text-xxs font-normal">はい</rt>
            っていない
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q4",
    "question": <>
      Q4 :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      やスポーツをどのくらいしていますか？
    </>,
    "options": {
      1: (
        <>
          ほとんど
          <ruby>
            毎日
            <rt className="text-xxs font-normal">まいにち</rt>
          </ruby>
          （
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に3
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
          ）
        </>
      ),
      2: (
        <>
          <ruby>
            時々
            <rt className="text-xxs font-normal">ときどき</rt>
          </ruby>
          （
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1〜2
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
          くらい）
        </>
      ),
      3: (
        <>
          ときたま（
          <ruby>
            月
            <rt className="text-xxs font-normal">つき</rt>
          </ruby>
          1〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
          くらい）
        </>
      ),
      4: (
        <>
          <ruby>
            しない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q5",
    "question": <>
      Q5 :
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      やスポーツをするときは、1
      <ruby className="font-black">
        日
        <rt className="text-xxs font-normal">にち</rt>
      </ruby>
      どのくらいの
      <ruby className="font-black">
        時間
        <rt className="text-xxs font-normal">じかん</rt>
      </ruby>
      しますか？
    </>,
    "options": {
      1: (
        <>30
          <ruby>
            分未満
            <rt className="text-xxs font-normal">ふんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>30
          <ruby>
            分以上
            <rt className="text-xxs font-normal">ふんいじょう</rt>
          </ruby>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q6",
    "question": <>
      Q6 :
      <ruby className="font-black">
        土曜日
        <rt className="text-xxs font-normal">どようび</rt>
      </ruby>
      や
      <ruby className="font-black">
        日曜日
        <rt className="text-xxs font-normal">にちようび</rt>
      </ruby>
      に
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      やスポーツをしていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            している
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            していない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q7",
    "question": <>
      Q7 :
      <ruby className="font-black">
        休
        <rt className="text-xxs font-normal">やす</rt>
      </ruby>
      み
      <ruby className="font-black">
        時間
        <rt className="text-xxs font-normal">じかん</rt>
      </ruby>
      や
      <ruby className="font-black">
        放課後
        <rt className="text-xxs font-normal">ほうかご</rt>
      </ruby>
      、
      <ruby className="font-black">
        休日
        <rt className="text-xxs font-normal">きゅうじつ</rt>
      </ruby>
      などに
      <ruby className="font-black">
        自分
        <rt className="text-xxs font-normal">じぶん</rt>
      </ruby>
      から
      <ruby className="font-black">
        進
        <rt className="text-xxs font-normal">すす</rt>
      </ruby>
      んで
      <ruby className="font-black">
        運動
        <rt className="text-xxs font-normal">うんどう</rt>
      </ruby>
      するようにしていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            している
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            少
            <rt className="text-xxs font-normal">すこ</rt>
          </ruby>
          ししている
        </>
      ),
      3: (
        <>
          <ruby>
            あまりしていない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            していない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q8",
    "question": <>
      Q8 :
      <ruby className="font-black">
        健康
        <rt className="text-xxs font-normal">けんこう</rt>
      </ruby>
      や
      <ruby className="font-black">
        体力
        <rt className="text-xxs font-normal">たいりょく</rt>
      </ruby>
      に
      <ruby className="font-black">
        自信
        <rt className="text-xxs font-normal">じしん</rt>
      </ruby>
      がありますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            自信
            <rt className="text-xxs font-normal">じしん</rt>
          </ruby>
          がある
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            自信
            <rt className="text-xxs font-normal">じしん</rt>
          </ruby>
          がある
        </>
      ),
      3: (
        <>
          やや
          <ruby>
            自信
            <rt className="text-xxs font-normal">じしん</rt>
          </ruby>
          がない
        </>
      ),
      4: (
        <>
          <ruby>
            自信
            <rt className="text-xxs font-normal">じしん</rt>
          </ruby>
          がない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q9",
    "question": <>
      Q9 :
      <ruby className="font-black">
        早寝
        <rt className="text-xxs font-normal">はやね</rt>
      </ruby>
      <ruby className="font-black">
        早起
        <rt className="text-xxs font-normal">はやお</rt>
      </ruby>
      きを
      <ruby>
        心
        <rt className="text-xxs font-normal">こころ</rt>
      </ruby>
      がけていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            心
            <rt className="text-xxs font-normal">こころ</rt>
          </ruby>
          がけている
        </>
      ),
      2: (
        <>
          <ruby>
            少
            <rt className="text-xxs font-normal">すこ</rt>
          </ruby>
          し
          <ruby>
            心
            <rt className="text-xxs font-normal">こころ</rt>
          </ruby>
          がけている
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            心
            <rt className="text-xxs font-normal">こころ</rt>
          </ruby>
          がけていない
        </>
      ),
      4: (
        <>
          <ruby>
            心
            <rt className="text-xxs font-normal">こころ</rt>
          </ruby>
          がけていない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q10",
    "question": <>
      Q10 :
      <ruby className="font-black">
        健康診断
        <rt className="text-xxs font-normal">けんこうしんだん</rt>
      </ruby>
      で
      <ruby className="font-black">
        指摘
        <rt className="text-xxs font-normal">してき</rt>
      </ruby>
      されたところは、
      <ruby className="font-black">
        早
        <rt className="text-xxs font-normal">はや</rt>
      </ruby>
      めに
      <ruby className="font-black">
        治療
        <rt className="text-xxs font-normal">ちりょう</rt>
      </ruby>
      していますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            している
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          ある
          <ruby>
            程度
            <rt className="text-xxs font-normal">ていど</rt>
          </ruby>
          している
        </>
      ),
      3: (
        <>
          <ruby>
            ほとんどしていない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            していない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q11",
    "question": <>
      Q11 : 1
      <ruby className="font-black">
        日
        <rt className="text-xxs font-normal">にち</rt>
      </ruby>
      の
      <ruby className="font-black">
        睡眠時間
        <rt className="text-xxs font-normal">すいみんじかん</rt>
      </ruby>
      はどのくらいですか？
    </>,
    "options": {
      1: (
        <>
          8
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          6
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          8
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          6
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q12",
    "question": <>
      Q1２ : 1
      <ruby className="font-black">
        日
        <rt className="text-xxs font-normal">にち</rt>
      </ruby>
      どのくらいテレビをみますか？（ゲーム、スマートフォン、パソコンなどを
      <ruby className="font-black">
        含
        <rt className="text-xxs font-normal">ふく</rt>
      </ruby>
      む）
    </>,
    "options": {
      1: (
        <>
          1
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          1
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          2
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          2
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
          3
          <ruby>
            時間未満
            <rt className="text-xxs font-normal">じかんみまん</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          3
          <ruby>
            時間以上
            <rt className="text-xxs font-normal">じかんいじょう</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q13",
    "question": <>
      Q13 :
      <ruby>
        朝食
        <rt className="text-xxs font-normal">ちょうしょく</rt>
      </ruby>
      は
      <ruby>
        食
        <rt className="text-xxs font-normal">た</rt>
      </ruby>
      べますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            毎日
            <rt className="text-xxs font-normal">まいにち</rt>
          </ruby>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べる
        </>
      ),
      2: (
        <>
          <ruby>
            時々
            <rt className="text-xxs font-normal">ときどき</rt>
          </ruby>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>

          べない
        </>
      ),
      3: (
        <>
          <ruby>
            毎日
            <rt className="text-xxs font-normal">まいにち</rt>
          </ruby>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>

          べない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q14",
    "question": <>
      Q14 :
      <ruby className="font-black">
        朝昼晩
        <rt className="text-xxs font-normal">あさひるばん</rt>
      </ruby>
      3
      <ruby className="font-black">
        食
        <rt className="text-xxs font-normal">しょく</rt>
      </ruby>
      きちんと
      <ruby className="font-black">
        食
        <rt className="text-xxs font-normal">た</rt>
      </ruby>
      べていますか？
    </>,
    "options": {
      1: (
        <>
          きちんと
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べる
        </>
      ),
      2: (
        <>
          <ruby>
            時々食
            <rt className="text-xxs font-normal">ときどきた</rt>
          </ruby>
          べない
        </>
      ),
      3: (
        <>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べないことが
          <ruby>
            多
            <rt className="text-xxs font-normal">おお</rt>
          </ruby>
          い
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q15",
    "question": <>
      Q15:
      <ruby className="font-black">
        好
        <rt className="text-xxs font-normal">す</rt>
      </ruby>
      き
      <ruby className="font-black">
        嫌
        <rt className="text-xxs font-normal">きら</rt>
      </ruby>
      いをしないで
      <ruby className="font-black">
        食
        <rt className="text-xxs font-normal">た</rt>
      </ruby>
      べていますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べている
        </>
      ),
      2: (
        <>
          だいたい
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べている
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べていない
        </>
      ),
      4: (
        <>
          <ruby>
            食
            <rt className="text-xxs font-normal">た</rt>
          </ruby>
          べていない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q16",
    "question": <>
      Q16:
      <ruby className="font-black">
        食事
        <rt className="text-xxs font-normal">しょくじ</rt>
      </ruby>
      のマナーに
      <ruby className="font-black">
        関心
        <rt className="text-xxs font-normal">かんしん</rt>
      </ruby>
      がありますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            ある
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            ややある
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            あまりない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            ない
            <rt className="text-xxs font-normal">&nbsp;</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q17",
    "question": <>
      Q17: ぐっすり
      <ruby className="font-black">
        眠
        <rt className="text-xxs font-normal">ねむ</rt>
      </ruby>
      る（よく
      <ruby className="font-black">
        眠
        <rt className="text-xxs font-normal">ねむ</rt>
      </ruby>
      る）ことができますか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に6
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に4〜5
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に2〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1
          <ruby>
            日以下
            <rt className="text-xxs font-normal">にちいか</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q18",
    "question": <>
      Q18:
      <ruby className="font-black">
        小学校入学前
        <rt className="text-xxs font-normal">しょうがっこうにゅうがくまえ</rt>
      </ruby>
      はどのくらい
      <ruby className="font-black">
        外
        <rt className="text-xxs font-normal">そと</rt>
      </ruby>
      で
      <ruby className="font-black">
        体
        <rt className="text-xxs font-normal">からだ</rt>
      </ruby>
      を
      <ruby className="font-black">
        動
        <rt className="text-xxs font-normal">うご</rt>
      </ruby>
      かす
      <ruby className="font-black">
        遊
        <rt className="text-xxs font-normal">あそ</rt>
      </ruby>
      びをしていましたか？
    </>,
    "options": {
      1: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に6
          <ruby>
            日以上
            <rt className="text-xxs font-normal">にちいじょう</rt>
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に4〜5
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      3: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に2〜3
          <ruby>
            日
            <rt className="text-xxs font-normal">にち</rt>
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            週
            <rt className="text-xxs font-normal">しゅう</rt>
          </ruby>
          に1
          <ruby>
            日以下
            <rt className="text-xxs font-normal">にちいか</rt>
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q19",
    "question": <>
      Q19:
      <ruby className="font-black">
        所属
        <rt className="text-xxs font-normal">しょぞく</rt>
      </ruby>
      している
      <ruby className="font-black">
        習
        <rt className="text-xxs font-normal">なら</rt>
      </ruby>
      い
      <ruby className="font-black">
        事
        <rt className="text-xxs font-normal">ごと</rt>
      </ruby>
    </>,
    "options": [
      "サッカー",
      "野球",
      "軟式野球",
      "バスケットボール",
      "バレーボール",
      "テニス",
      "ソフトテニス",
      "バドミントン",
      "卓球",
      "陸上",
      "ハンドボール",
      "剣道",
      "柔道",
      "空手",
      "弓道",
      "水泳",
      "ソフトボール",
      "体操",
      "新体操",
      "アメリカンフットボール",
      "ラグビー",
      "スキー",
      "スケート",
      "相撲",
      "その他",
      "所属していない"
    ]
  },
  {
    "type": "junior",
    "id": "q1",
    "question": (
      <>
        Q1 : 運動やスポーツをすることは好きですか？
      </>
    ),
    "options": {
      1: (
        <>
          好き
        </>
      ),
      2: (
        <>
          やや好き
        </>
      ),
      3: (
        <>
          ややきらい
        </>
      ),
      4: (
        <>
          きらい
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q2",
    "question": (
      <>
        Q2 :運動やスポーツをすることは得意ですか？
      </>
    ),
    "options": {
      1: (
        <>
          得意
        </>
      ),
      2: (
        <>
          やや得意
        </>
      ),
      3: (
        <>
          やや不得意
        </>
      ),
      4: (
        <>
          不得意
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q3",
    "question": (
      <>
        Q3 : 運動部やスポーツクラブに入っていますか？
      </>
    ),
    "options": {
      1: (
        <>
          入っている
        </>
      ),
      2: (
        <>
          入っていない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q4",
    "question": (
      <>
        Q4 :運動やスポーツをどのくらいしていますか？
      </>
    ),
    "options": {
      1: (
        <>
          ほとんど毎日（週に3日以上）
        </>
      ),
      2: (
        <>
          時々（週に1〜2日くらい）
        </>
      ),
      3: (
        <>
          ときたま（月1〜3日くらい）
        </>
      ),
      4: (
        <>
          しない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q5",
    "question": (
      <>
        Q5 :運動やスポーツをするときは、1日どのくらいの時間しますか？
      </>
    ),
    "options": {
      1: (
        <>
          30分未満
        </>
      ),
      2: (
        <>
          30分以上1時間未満
        </>
      ),
      3: (
        <>
          1時間以上2時間未満
        </>
      ),
      4: (
        <>
          2時間以上
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q6",
    "question": (
      <>
        Q6 :土曜日や日曜日に運動やスポーツをしていますか？
      </>
    ),
    "options": {
      1: (
        <>
          している
        </>
      ),
      2: (
        <>
          していない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q7",
    "question": (
      <>
        Q7 : 休み時間や放課後、休日などに自分から進んで運動するようにしていますか？
      </>
    ),
    "options": {
      1: (
        <>
          している
        </>
      ),
      2: (
        <>
          少ししている
        </>
      ),
      3: (
        <>
          あまりしていない
        </>
      ),
      4: (
        <>
          していない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q8",
    "question": (
      <>
        Q8 : 健康や体力に自信がありますか？
      </>
    ),
    "options": {
      1: (
        <>
          自信がある
        </>
      ),
      2: (
        <>
          やや自信がある
        </>
      ),
      3: (
        <>
          やや自信がない
        </>
      ),
      4: (
        <>
          自信がない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q9",
    "question": (
      <>
        Q9 : 早寝早起きを心がけていますか？
      </>
    ),
    "options": {
      1: (
        <>
          心がけている
        </>
      ),
      2: (
        <>
          少し心がけている
        </>
      ),
      3: (
        <>
          あまり心がけていない
        </>
      ),
      4: (
        <>
          心がけていない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q10",
    "question": (
      <>
        Q10 : 健康診断で指摘されたところは、早めに治療していますか？
      </>
    ),
    "options": {
      1: (
        <>
          している
        </>
      ),
      2: (
        <>
          ある程度している
        </>
      ),
      3: (
        <>
          ほとんどしていない
        </>
      ),
      4: (
        <>
          していない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q11",
    "question": (
      <>
        Q11 : 1日の睡眠時間はどのくらいですか？
      </>
    ),
    "options": {
      1: (
        <>
          8時間以上
        </>
      ),
      2: (
        <>
          6時間以上8時間未満
        </>
      ),
      3: (
        <>
          6時間未満
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q12",
    "question": (
      <>
        Q12 : 1日どのくらいテレビをみますか？（ゲーム、スマートフォン、パソコンなどを含む）
      </>
    ),
    "options": {
      1: (
        <>
          1時間未満
        </>
      ),
      2: (
        <>
          1時間以上2時間未満
        </>
      ),
      3: (
        <>
          2時間以上3時間未満
        </>
      ),
      4: (
        <>
          3時間以上
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q13",
    "question": (
      <>
        Q13 : 朝食は食べますか？
      </>
    ),
    "options": {
      1: (
        <>
          毎日食べる
        </>
      ),
      2: (
        <>
          時々食べない
        </>
      ),
      3: (
        <>
          食べないことが多い
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q14",
    "question": (
      <>
        Q14 :朝昼晩3食きちんと食べていますか？
      </>
    ),
    "options": {
      1: (
        <>
          きちんと食べる
        </>
      ),
      2: (
        <>
          時々食べない
        </>
      ),
      3: (
        <>
          食べないことが多い
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q15",
    "question": (
      <>
        Q15: 好き嫌いをしないで食べていますか？
      </>
    ),
    "options": {
      1: (
        <>
          食べている
        </>
      ),
      2: (
        <>
          だいたい食べている
        </>
      ),
      3: (
        <>
          あまり食べていない
        </>
      ),
      4: (
        <>
          食べていない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q16",
    "question": (
      <>
        Q16: 食事のマナーに関心がありますか？
      </>
    ),
    "options": {
      1: (
        <>
          ある
        </>
      ),
      2: (
        <>
          ややある
        </>
      ),
      3: (
        <>
          あまりない
        </>
      ),
      4: (
        <>
          ない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q17",
    "question": (
      <>
        Q17: ぐっすり眠る（よく眠る）ことができますか？
      </>
    ),
    "options": {
      1: (
        <>
          週に6日以上
        </>
      ),
      2: (
        <>
          週に4〜5日
        </>
      ),
      3: (
        <>
          週に2〜3日
        </>
      ),
      4: (
        <>
          週に1日以下
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q18",
    "question": (
      <>
        Q18:なんでも最後までやりとげたいと思う
      </>
    ),
    "options": {
      1: (
        <>
          とてもそう思う
        </>
      ),
      2: (
        <>
          まあそう思う
        </>
      ),
      3: (
        <>
          あまりそう思わない
        </>
      ),
      4: (
        <>
          まったくそう思わない
        </>
      )
    }
  },
  {
    "type": "junior",
    "id": "q19",
    "question": (
      <>
        Q19 : 学校内で所属している部活動
      </>
    ),
    "options": [
      "サッカー",
      "野球",
      "軟式野球",
      "バスケットボール",
      "バレーボール",
      "テニス",
      "ソフトテニス",
      "バドミントン",
      "卓球",
      "陸上",
      "ハンドボール",
      "剣道",
      "柔道",
      "空手",
      "弓道",
      "水泳",
      "ソフトボール",
      "体操",
      "新体操",
      "アメリカンフットボール",
      "ラグビー",
      "スキー",
      "スケート",
      "相撲",
      "その他",
      "所属していない"
    ]
  }
]

export const KOBE = [
  {
    "type": "elementary",
    "id": "q1",
    "question": <>
      Q1 :<ruby className="font-black">運動<rt className="text-xxs font-normal">うんどう</rt></ruby>
      （<ruby className="font-black">体<rt className="text-xxs font-normal">からだ</rt></ruby>
      を<ruby className="font-black">動<rt className="text-xxs font-normal">うご</rt></ruby>
      かす<ruby className="font-black">遊<rt className="text-xxs font-normal">あそ</rt></ruby>
      びをふくむ）やスポーツをすることは<ruby className="font-black">好<rt className="text-xxs font-normal">す</rt></ruby>きですか。
    </>,
    "options": {
      1: (
        <>
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
            き
          </ruby>
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            好
            <rt className="text-xxs font-normal">す</rt>
            き
          </ruby>
        </>
      ),
      3: (
        <>
          やや
          <ruby>
            嫌
            <rt className="text-xxs font-normal">きら</rt>
          </ruby>
          い
        </>
      ),
      4: (
        <>
          <ruby>
            嫌
            <rt className="text-xxs font-normal">きら</rt>
          </ruby>
          い
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q2",
    "question": <>
      Q2 :
      あなたにとって<ruby className="font-black">運動<rt className="text-xxs font-normal">うんどう</rt></ruby>
      （<ruby className="font-black">体<rt className="text-xxs font-normal">からだ</rt></ruby>を
      <ruby className="font-black">動<rt className="text-xxs font-normal">うご</rt></ruby>かす
      <ruby className="font-black">遊<rt className="text-xxs font-normal">あそ</rt></ruby>びをふくむ）やスポーツは
      <ruby className="font-black">大切<rt className="text-xxs font-normal">たいせつ</rt></ruby>なものですか。
    </>,
    "options": {
      1: (
        <>
          <ruby className="font-black">大切<rt className="text-xxs font-normal">たいせつ</rt></ruby>
        </>
      ),
      2: (
        <>
          やや
          <ruby className="font-black">大切<rt className="text-xxs font-normal">たいせつ</rt></ruby>
        </>
      ),
      3: (
        <>
          あまり<ruby className="font-black">大切<rt className="text-xxs font-normal">たいせつ</rt></ruby>ではない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">大切<rt className="text-xxs font-normal">たいせつ</rt></ruby>ではない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q3",
    "question": <>
      Q3 :
      <ruby className="font-black">中学校<rt className="text-xxs font-normal">ちゅうがっこう</rt></ruby>
      に<ruby className="font-black">進<rt className="text-xxs font-normal">すす</rt></ruby>んだら、
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>
      <ruby className="font-black">以外<rt className="text-xxs font-normal">いがい</rt></ruby>でも
      <ruby className="font-black">自主的<rt className="text-xxs font-normal">じしゅてき</rt></ruby>に
      <ruby className="font-black">運動<rt className="text-xxs font-normal">うんどう</rt></ruby>
      （<ruby className="font-black">体<rt className="text-xxs font-normal">からだ</rt></ruby>を
      <ruby className="font-black">動<rt className="text-xxs font-normal">うご</rt></ruby>かす
      <ruby className="font-black">遊<rt className="text-xxs font-normal">あそ</rt></ruby>びをふくむ）やスポーツをする
      <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>を
      <ruby className="font-black">持<rt className="text-xxs font-normal">も</rt></ruby>ちたいと
      <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>いますか。
    </>,
    "options": {
      1: (
        <>
          <ruby>
            思
            <rt className="text-xxs font-normal">おも</rt>
            う
          </ruby>
        </>
      ),
      2: (
        <>
          やや
          <ruby>
            思
            <rt className="text-xxs font-normal">おも</rt>
            う
          </ruby>
        </>
      ),
      3: (
        <>
          あまり
          <ruby>
            思
            <rt className="text-xxs font-normal">おも</rt>
            わない
          </ruby>
        </>
      ),
      4: (
        <>
          <ruby>
            思
            <rt className="text-xxs font-normal">おも</rt>
            わない
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q4",
    "question": <>
      Q3-2 :
      【<ruby>質問<rt className="text-xxs font-normal">しつもん</rt></ruby>3.で
      「<ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>う」または
      「やや<ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>う」
      と<ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>した<ruby>人<rt className="text-xxs font-normal">ひと</rt></ruby>】<br/>
      そのように<ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>う
      <ruby>理由<rt className="text-xxs font-normal">りゆう</rt></ruby>は
      <ruby>何<rt className="text-xxs font-normal">なに</rt></ruby>ですか。
      <ruby>当<rt className="text-xxs font-normal">あ</rt></ruby>てはまるものをすべて
      <ruby>選<rt className="text-xxs font-normal">えら</rt></ruby>んでください<br/>
      ※<ruby>質問<rt className="text-xxs font-normal">しつもん</rt></ruby>3で
      「あまり<ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>うわない」または
      「<ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>うわない」
      と<ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>した
      <ruby>人<rt className="text-xxs font-normal">ひと</rt></ruby>は9
      「<ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>しない」を
      <ruby>選<rt className="text-xxs font-normal">えら</rt></ruby>んでください。
    </>,
    "dependences": {
      "q3": [0, 1]
    },
    "multiple": true,
    "options": {
      1: (
        <>
          <ruby>今<rt className="text-xxs font-normal">いま</rt></ruby>
          <ruby>取<rt className="text-xxs font-normal">と</rt></ruby>り
          <ruby>組<rt className="text-xxs font-normal">く</rt></ruby>んでいるスポーツを
          <ruby>続<rt className="text-xxs font-normal">つづ</rt></ruby>けたいから
        </>
      ),
      2: (
        <>
          <ruby>新<rt className="text-xxs font-normal">あたら</rt></ruby>しいスポーツに
          <ruby>取<rt className="text-xxs font-normal">と</rt></ruby>り
          <ruby>組<rt className="text-xxs font-normal">く</rt></ruby>んでみたいから
        </>
      ),
      3: (
        <>
          <ruby>体<rt className="text-xxs font-normal">からだ</rt></ruby>を
          <ruby>動<rt className="text-xxs font-normal">うご</rt></ruby>かすことが
          <ruby>好<rt className="text-xxs font-normal">す</rt></ruby>きだから
        </>
      ),
      4: (
        <>
          <ruby>健康<rt className="text-xxs font-normal">けんこう</rt></ruby>に
          <ruby>生活<rt className="text-xxs font-normal">せいかつ</rt></ruby>できるようになるから
        </>
      ),
      5: (
        <>
          <ruby>親<rt className="text-xxs font-normal">おや</rt></ruby>に
          <ruby>勧<rt className="text-xxs font-normal">すす</rt></ruby>められているから
        </>
      ),
      6: (
        <>
          <ruby>友達<rt className="text-xxs font-normal">ともだち</rt></ruby>と
          <ruby>一緒<rt className="text-xxs font-normal">いっしょ</rt></ruby>に
          <ruby>活動<rt className="text-xxs font-normal">かつどう</rt></ruby>したいから
        </>
      ),
      7: (
        <>
          スポーツ<ruby>選手<rt className="text-xxs font-normal">せんしゅ</rt></ruby>になる
          <ruby>目標<rt className="text-xxs font-normal">もくひょう</rt></ruby>があるから
        </>
      ),
      8: (
        <>
          その<ruby>他<rt className="text-xxs font-normal">た</rt></ruby>
        </>
      ),
      9: (
        <>
          <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>しない
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q5",
    "question": <>
      Q3-3 :
      【<ruby>質問<rt className="text-xxs font-normal">しつもん</rt></ruby>3.で「あまり
      <ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>わない」または「
      <ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>わない」と
      <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>した
      <ruby>人<rt className="text-xxs font-normal">ひと</rt></ruby>】<br/>
      <ruby>今後<rt className="text-xxs font-normal">こんご</rt></ruby>どのようなことがあれば、
      <ruby>卒業後<rt className="text-xxs font-normal">そつぎょうご</rt></ruby>も
      <ruby>運動<rt className="text-xxs font-normal">うんどう</rt></ruby>したくなると
      <ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>いますか。
      <ruby>当<rt className="text-xxs font-normal">あ</rt></ruby>てはまるものをすべて
      <ruby>選<rt className="text-xxs font-normal">えら</rt></ruby>んでください。<br/>
      ※<ruby>質問<rt className="text-xxs font-normal">しつもん</rt></ruby>3で「
      <ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>う」または「やや
      <ruby>思<rt className="text-xxs font-normal">おも</rt></ruby>う」と
      <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>した
      <ruby>人<rt className="text-xxs font-normal">ひと</rt></ruby>は9「
      <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>しない」を
      <ruby>選<rt className="text-xxs font-normal">えら</rt></ruby>んでください。<br/>
    </>,
    "dependences": {
      "q3": [2, 3]
    },
    "multiple": true,
    "options": {
      1: (
        <>
        うまくできるようになったら
        </>
      ),
      2: (
        <>
        <ruby>自分<rt className="text-xxs font-normal">じぶん</rt></ruby>に
        <ruby>合<rt className="text-xxs font-normal">あ</rt></ruby>ったスポーツが
        <ruby>見<rt className="text-xxs font-normal">み</rt></ruby>つけられたら
        </>
      ),
      3: (
        <>
        <ruby>友達<rt className="text-xxs font-normal">ともだち</rt></ruby>に
        <ruby>誘<rt className="text-xxs font-normal">さそ</rt></ruby>われたら
        </>
      ),
      4: (
        <>
        <ruby>親<rt className="text-xxs font-normal">おや</rt></ruby>に
        <ruby>勧<rt className="text-xxs font-normal">すす</rt></ruby>められたら
        </>
      ),
      5: (
        <>
        <ruby>専門家<rt className="text-xxs font-normal">せんもんか</rt></ruby>に
        <ruby>教<rt className="text-xxs font-normal">おし</rt></ruby>えてもらえたら
        </>
      ),
      6: (
        <>
        <ruby>自分<rt className="text-xxs font-normal">じぶん</rt></ruby>のペースでおこなうことができたら
        </>
      ),
      7: (
        <>
        <ruby>気軽<rt className="text-xxs font-normal">きがる</rt></ruby>に
        <ruby>運動<rt className="text-xxs font-normal">うんどう</rt></ruby>できる
        <ruby>場所<rt className="text-xxs font-normal">ばしょ</rt></ruby>があれば
        </>
      ),
      8: (
        <>
        その<ruby>他<rt className="text-xxs font-normal">た</rt></ruby>
        </>
      ),
      9: (
        <>
        <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>しない
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q6",
    "question": <>
      Q4 :
      <ruby>地域<rt className="text-xxs font-normal">ちいき</rt></ruby>
      のスポーツクラブ（スポーツ
      <ruby>少年団<rt className="text-xxs font-normal">しょうねんだん</rt></ruby>や
      <ruby>習<rt className="text-xxs font-normal">なら</rt></ruby>い
      <ruby>事<rt className="text-xxs font-normal">ごと</rt></ruby>をふくみます）に
      <ruby>入<rt className="text-xxs font-normal">はい</rt></ruby>っていますか。
    </>,
    "options": {
      1: (
        <>
          <ruby>
            入
            <rt className="text-xxs font-normal">はい</rt>
            っている
          </ruby>
        </>
      ),
      2: (
        <>
          <ruby>
            入
            <rt className="text-xxs font-normal">はい</rt>
            っていない
          </ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q7",
    "question": <>
      Q4-2 :
      【<ruby>質問<rt className="text-xxs font-normal">しつもん</rt></ruby>4.で「
      <ruby>入<rt className="text-xxs font-normal">はい</rt></ruby>っている」と
      <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>した
      <ruby>人<rt className="text-xxs font-normal">ひと</rt></ruby>】<br/>
      <ruby>地域<rt className="text-xxs font-normal">ちいき</rt></ruby>のスポーツクラブ（スポーツ
      <ruby>少年団<rt className="text-xxs font-normal">しょうねんだん</rt></ruby>や
      <ruby>習<rt className="text-xxs font-normal">なら</rt></ruby>い
      <ruby>事<rt className="text-xxs font-normal">ごと</rt></ruby>をふくみます）での
      <ruby>活動<rt className="text-xxs font-normal">かつどう</rt></ruby>
      <ruby>回数<rt className="text-xxs font-normal">かいすう</rt></ruby>は、どれぐらいですか。<br/>
      ※<ruby>質問<rt className="text-xxs font-normal">しつもん</rt></ruby>4で「
      <ruby>入<rt className="text-xxs font-normal">はい</rt></ruby>っていない」と
      <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>した
      <ruby>人<rt className="text-xxs font-normal">ひと</rt></ruby>は8「
      <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>しない」を
      <ruby>選択<rt className="text-xxs font-normal">せんたく</rt></ruby>してください。
    </>,
    "dependences": {
      "q4": [0]
    },
    "options": {
      1: (
        <>
          <ruby>週<rt className="text-xxs font-normal">しゅう</rt></ruby>１<ruby>回<rt className="text-xxs font-normal">かい</rt></ruby>
        </>
      ),
      2: (
        <>
          <ruby>週<rt className="text-xxs font-normal">しゅう</rt></ruby>２<ruby>回<rt className="text-xxs font-normal">かい</rt></ruby>
        </>
      ),
      3: (
        <>
          <ruby>週<rt className="text-xxs font-normal">しゅう</rt></ruby>３<ruby>回<rt className="text-xxs font-normal">かい</rt></ruby>
        </>
      ),
      4: (
        <>
          <ruby>週<rt className="text-xxs font-normal">しゅう</rt></ruby>４<ruby>回<rt className="text-xxs font-normal">かい</rt></ruby>
        </>
      ),
      5: (
        <>
          <ruby>週<rt className="text-xxs font-normal">しゅう</rt></ruby>５<ruby>回<rt className="text-xxs font-normal">かい</rt></ruby>
        </>
      ),
      6: (
        <>
          <ruby>週<rt className="text-xxs font-normal">しゅう</rt></ruby>６<ruby>回<rt className="text-xxs font-normal">かい</rt></ruby>
        </>
      ),
      7: (
        <>
          <ruby>毎日<rt className="text-xxs font-normal">まいにち</rt></ruby>
        </>
      ),
      8: (
        <>
          <ruby>回答<rt className="text-xxs font-normal">かいとう</rt></ruby>しない
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q8",
    "question": <>
      Q5 :
      ふだんの1<ruby>週間<rt className="text-xxs font-normal">しゅうかん</rt></ruby>について
      <ruby>聞<rt className="text-xxs font-normal">き</rt></ruby>きます。
      <ruby>学校<rt className="text-xxs font-normal">がっこう</rt></ruby>の
      <ruby>体育<rt className="text-xxs font-normal">たいいく</rt></ruby>の
      <ruby>授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>
      <ruby>以外<rt className="text-xxs font-normal">いがい</rt></ruby>で、
      <ruby>運動<rt className="text-xxs font-normal">うんどう</rt></ruby>（
      <ruby>体<rt className="text-xxs font-normal">からだ</rt></ruby>を
      <ruby>動<rt className="text-xxs font-normal">うご</rt></ruby>かす
      <ruby>遊<rt className="text-xxs font-normal">あそ</rt></ruby>びをふくむ）やスポーツを
      <ruby>合計<rt className="text-xxs font-normal">ごうけい</rt></ruby>で1
      <ruby>日<rt className="text-xxs font-normal">にち</rt></ruby>およそどのくらいの
      <ruby>時間<rt className="text-xxs font-normal">じかん</rt></ruby>していますか。それぞれの
      <ruby>曜日<rt className="text-xxs font-normal">ようび</rt></ruby>のらんに
      <ruby>記入<rt className="text-xxs font-normal">きにゅう</rt></ruby>してください。<br/>
      ※<ruby>運動<rt className="text-xxs font-normal">うんどう</rt></ruby>しない
      <ruby>場合<rt className="text-xxs font-normal">ばあい</rt></ruby>は、「0」を
      <ruby>入力<rt className="text-xxs font-normal">にゅうりょく</rt></ruby>してください。
    </>,
    "options": [
      <ruby>月<rt className="text-xxs font-normal">げつ</rt></ruby>,
      <ruby>火<rt className="text-xxs font-normal">か</rt></ruby>,
      <ruby>水<rt className="text-xxs font-normal">すい</rt></ruby>,
      <ruby>木<rt className="text-xxs font-normal">もく</rt></ruby>,
      <ruby>金<rt className="text-xxs font-normal">きん</rt></ruby>,
      <ruby>土<rt className="text-xxs font-normal">ど</rt></ruby>,
      <ruby>日<rt className="text-xxs font-normal">にち</rt></ruby>,
    ]
  },
  {
    "type": "elementary",
    "id": "q9",
    "question": <>
      Q6 :
      <ruby className="font-black">朝食<rt className="text-xxs font-normal">ちょうしょく</rt></ruby>は
      <ruby className="font-black">毎日<rt className="text-xxs font-normal">まいにち</rt></ruby>
      <ruby className="font-black">食<rt className="text-xxs font-normal">た</rt></ruby>べますか。（
      <ruby className="font-black">学校<rt className="text-xxs font-normal">がっこう</rt></ruby>が
      <ruby className="font-black">休<rt className="text-xxs font-normal">やす</rt></ruby>みの
      <ruby className="font-black">日<rt className="text-xxs font-normal">ひ</rt></ruby>もふくめます）
    </>,
    "options": {
      1: (
        <>
          <ruby>毎日<rt className="text-xxs font-normal">まいにち</rt></ruby>
          <ruby>食<rt className="text-xxs font-normal">た</rt></ruby>べる
        </>
      ),
      2: (
        <>
          <ruby>食<rt className="text-xxs font-normal">た</rt></ruby>べない
          <ruby>日<rt className="text-xxs font-normal">ひ</rt></ruby>もある
        </>
      ),
      3: (
        <>
          <ruby>食<rt className="text-xxs font-normal">た</rt></ruby>べない
          <ruby>日<rt className="text-xxs font-normal">ひ</rt></ruby>が
          <ruby>多<rt className="text-xxs font-normal">おお</rt></ruby>い
        </>
      ),
      4: (
        <>
          <ruby>食<rt className="text-xxs font-normal">た</rt></ruby>べない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q10",
    "question": <>
      Q7 :
      <ruby className="font-black">毎日<rt className="text-xxs font-normal">まいにち</rt></ruby>どのくらいねていますか。
    </>,
    "options": {
      1: (
        <>
          １０<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>
        </>
      ),
      2: (
        <>
          ９<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>１０
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      3: (
        <>
          ８<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>９
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      4: (
        <>
          ７<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>８
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      5: (
        <>
          ６<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>７
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      6: (
        <>
          ６<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q11",
    "question": <>
      Q8 : 
      <ruby className="font-black">平日<rt className="text-xxs font-normal">へいじつ</rt></ruby>
      （<ruby className="font-black">月<rt className="text-xxs font-normal">げつ</rt></ruby>～
      <ruby className="font-black">金曜日<rt className="text-xxs font-normal">きんようび</rt></ruby>）について
      <ruby className="font-black">聞<rt className="text-xxs font-normal">き</rt></ruby>きます。
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>
      <ruby className="font-black">以外<rt className="text-xxs font-normal">いがい</rt></ruby>で、１
      <ruby className="font-black">日<rt className="text-xxs font-normal">にち</rt></ruby>にどのくらいの
      <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>、テレビやDVD、ゲーム
      <ruby className="font-black">機<rt className="text-xxs font-normal">き</rt></ruby>、スマートフォン、パソコンなどの
      <ruby className="font-black">画面<rt className="text-xxs font-normal">がめん</rt></ruby>を
      <ruby className="font-black">見<rt className="text-xxs font-normal">み</rt></ruby>ていますか。
    </>,
    "options": {
      1: (
        <>
          ５<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>
        </>
      ),
      2: (
        <>
         4<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>５
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      3: (
        <>
          ３<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>４
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      4: (
        <>
          ２<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>３
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      5: (
        <>
          １<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">以上<rt className="text-xxs font-normal">いじょう</rt></ruby>２
          <ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      6: (
        <>
          １<ruby className="font-black">時間<rt className="text-xxs font-normal">じかん</rt></ruby>
          <ruby className="font-black">未満<rt className="text-xxs font-normal">みまん</rt></ruby>
        </>
      ),
      7: (
        <>
          まったく<ruby className="font-black">見<rt className="text-xxs font-normal">み</rt></ruby>ない
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q12",
    "question": <>
      Q9 :
      <ruby className="font-black">体育<rt className="text-xxs font-normal">たいいく</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>は
      <ruby className="font-black">楽<rt className="text-xxs font-normal">たの</rt></ruby>しいですか。
    </>,
    "options": {
      1: (
        <>
          <ruby className="font-black">楽<rt className="text-xxs font-normal">たの</rt></ruby>しい
        </>
      ),
      2: (
        <>
          やや<ruby className="font-black">楽<rt className="text-xxs font-normal">たの</rt></ruby>しい
        </>
      ),
      3: (
        <>
          あまり<ruby className="font-black">楽<rt className="text-xxs font-normal">たの</rt></ruby>しくない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">楽<rt className="text-xxs font-normal">たの</rt></ruby>しくない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q13",
    "question": <>
      Q10 :
      <ruby className="font-black">体育<rt className="text-xxs font-normal">たいいく</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>では、
      <ruby className="font-black">進<rt className="text-xxs font-normal">すす</rt></ruby>んで
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>に
      <ruby className="font-black">参加<rt className="text-xxs font-normal">さんか</rt></ruby>していますか。
    </>,
    "options": {
      1: (
        <>
          いつも<ruby className="font-black">進<rt className="text-xxs font-normal">すす</rt></ruby>んで
          <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>している
        </>
      ),
      2: (
        <>
          だいたい<ruby className="font-black">進<rt className="text-xxs font-normal">すす</rt></ruby>んで
          <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>している
        </>
      ),
      3: (
        <>
          あまり<ruby className="font-black">進<rt className="text-xxs font-normal">すす</rt></ruby>んで
          <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>していない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">進<rt className="text-xxs font-normal">すす</rt></ruby>んで
          <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>することはない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q14",
    "question": <>
      Q11 :
      <ruby className="font-black">体育<rt className="text-xxs font-normal">たいいく</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>で、
      <ruby className="font-black">目標<rt className="text-xxs font-normal">もくひょう</rt></ruby>（ねらい・めあて）を
      <ruby className="font-black">意識<rt className="text-xxs font-normal">いしき</rt></ruby>して
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>することで、「できたり、わかったり」することがありますか。
    </>,
    "options": {
      1: (
        <>
          いつもある
        </>
      ),
      2: (
        <>
          だいたいある
        </>
      ),
      3: (
        <>
          あまりない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">全<rt className="text-xxs font-normal">まった</rt></ruby>くない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q15",
    "question": <>
      Q12:
      <ruby className="font-black">体育<rt className="text-xxs font-normal">たいいく</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>で、
      <ruby className="font-black">友達<rt className="text-xxs font-normal">ともだち</rt></ruby>と
      <ruby className="font-black">助<rt className="text-xxs font-normal">たす</rt></ruby>け
      <ruby className="font-black">合<rt className="text-xxs font-normal">あ</rt></ruby>ったり、
      <ruby className="font-black">教<rt className="text-xxs font-normal">おし</rt></ruby>え
      <ruby className="font-black">合<rt className="text-xxs font-normal">あ</rt></ruby>ったりして
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>することで、「できたり、わかったり」することがありますか。
    </>,
    "options": {
      1: (
        <>
          いつもある
        </>
      ),
      2: (
        <>
          だいたいある
        </>
      ),
      3: (
        <>
          あまりない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">全<rt className="text-xxs font-normal">まった</rt></ruby>くない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q16",
    "question": <>
      Q13:
      <ruby className="font-black">体育<rt className="text-xxs font-normal">たいいく</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>で、タブレットなどのICTを
      <ruby className="font-black">使<rt className="text-xxs font-normal">つか</rt></ruby>って
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>することで、「できたり、わかったり」することがありますか。
    </>,
    "options": {
      1: (
        <>
          いつもある
        </>
      ),
      2: (
        <>
          だいたいある
        </>
      ),
      3: (
        <>
          あまりない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">全<rt className="text-xxs font-normal">まった</rt></ruby>くない
        </>
      ),
      5: (
        <>
          ICTを<ruby className="font-black">活用<rt className="text-xxs font-normal">かつよう</rt></ruby>していない
        </>
      ),
    }
  },
  {
    "type": "elementary",
    "id": "q17",
    "question": <>
      Q14:
      <ruby className="font-black">体育<rt className="text-xxs font-normal">たいりょく</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>の
      <ruby className="font-black">最後<rt className="text-xxs font-normal">さいご</rt></ruby>に、その
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>で
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>したことを
      <ruby className="font-black">振<rt className="text-xxs font-normal">ふ</rt></ruby>り
      <ruby className="font-black">返<rt className="text-xxs font-normal">かえ</rt></ruby>る
      <ruby className="font-black">活動<rt className="text-xxs font-normal">かつどう</rt></ruby>があることで、「できたり、わかったり」することがありますか。
    </>,
    "options": {
      1: (
        <>
          いつもある
        </>
      ),
      2: (
        <>
          だいたいある
        </>
      ),
      3: (
        <>
          あまりない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">全<rt className="text-xxs font-normal">まった</rt></ruby>くない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q18",
    "question": <>
      Q15:
      <ruby className="font-black">体力<rt className="text-xxs font-normal">たいりょく</rt></ruby>テストの
      <ruby className="font-black">結果<rt className="text-xxs font-normal">けっか</rt></ruby>や
      <ruby className="font-black">体力<rt className="text-xxs font-normal">たいりょく</rt></ruby>・
      <ruby className="font-black">運動<rt className="text-xxs font-normal">うんどう</rt></ruby>
      <ruby className="font-black">能力<rt className="text-xxs font-normal">のうりょく</rt></ruby>の
      <ruby className="font-black">向上<rt className="text-xxs font-normal">こうじょう</rt></ruby>について、
      <ruby className="font-black">自分<rt className="text-xxs font-normal">じぶん</rt></ruby>なりの
      <ruby className="font-black">目標<rt className="text-xxs font-normal">もくひょう</rt></ruby>を
      <ruby className="font-black">立<rt className="text-xxs font-normal">た</rt></ruby>てていますか。
    </>,
    "options": {
      1: (
        <>
          <ruby className="font-black">目標<rt className="text-xxs font-normal">もくひょう</rt></ruby>を
          <ruby className="font-black">立<rt className="text-xxs font-normal">た</rt></ruby>てている
        </>
      ),
      2: (
        <>
          <ruby className="font-black">目標<rt className="text-xxs font-normal">もくひょう</rt></ruby>は
          <ruby className="font-black">立<rt className="text-xxs font-normal">た</rt></ruby>てていない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q19",
    "question": <>
      Q16:
      <ruby className="font-black">保健<rt className="text-xxs font-normal">ほけん</rt></ruby>の
      <ruby className="font-black">授業<rt className="text-xxs font-normal">じゅぎょう</rt></ruby>で
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>した
      <ruby className="font-black">運動<rt className="text-xxs font-normal">うんどう</rt></ruby>、
      <ruby className="font-black">食事<rt className="text-xxs font-normal">しょくじ</rt></ruby>、
      <ruby className="font-black">休養<rt className="text-xxs font-normal">きゅうよう</rt></ruby>およびすいみんに
      <ruby className="font-black">気<rt className="text-xxs font-normal">き</rt></ruby>をつけた
      <ruby className="font-black">生活<rt className="text-xxs font-normal">せいかつ</rt></ruby>を
      <ruby className="font-black">送<rt className="text-xxs font-normal">おく</rt></ruby>れていると
      <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>いますか。
    </>,
    "options": {
      1: (
        <>
          <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>う
        </>
      ),
      2: (
        <>
          やや<ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>う
        </>
      ),
      3: (
        <>
          あまり<ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>わない
        </>
      ),
      4: (
        <>
          <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>わない
        </>
      )
    }
  },
  {
    "type": "elementary",
    "id": "q20",
    "question": <>
      Q17:
      <ruby className="font-black">保健<rt className="text-xxs font-normal">ほけん</rt></ruby>を
      <ruby className="font-black">学習<rt className="text-xxs font-normal">がくしゅう</rt></ruby>して、もっと
      <ruby className="font-black">運動<rt className="text-xxs font-normal">うんどう</rt></ruby>しようと
      <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>いましたか。
    </>,
    "options": {
      1: (
        <>
          <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>うようになった
        </>
      ),
      2: (
        <>
          やや<ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>うようになった
        </>
      ),
      3: (
        <>
          あまり<ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>わなかった
        </>
      ),
      4: (
        <>
          <ruby className="font-black">思<rt className="text-xxs font-normal">おも</rt></ruby>わなかった
        </>
      )
    }
  },
]