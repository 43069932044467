import { useApolloClient } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { Button, Modal, Row } from 'antd'

import { Card, HomeIcon, PlayIcon } from '@alpha/components'
import { logout } from '@alpha/core'
import { useAlphaStore } from '~/context'
import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { WarnIcon } from "../icons/WarnIcon";
import { useStudentMeApiQuery } from '~/utils/hooks'

export const Dashboard = ({
  navbar,
  children,
  isOpen = true,
  setIsOpen,
  selectedMenu = 1,
  loading = false,
  authenticated = true,
  customNavigate = false,
  defaultText = '戻る',
  onClickNavigate = () => { }
}) => {
  const client = useApolloClient()
  const history = useHistory()
  const { data: meData, loading: meLoading } = useStudentMeApiQuery()
  const { warnBeforeLeave, setWarnBeforeLeave } = useAlphaStore()
  const [showWarnModal, setShowWarnModal] = useState<any>(false)
  const [goingPath, setGoingPath] = useState<any>('')

  if (authenticated && !meLoading && !meData?.school) {
    return (
      <div className="w-screen h-screen flex items-center justify-center bg-gray-400 transition-opacity duration-500">
        <Card>
          <div className="flex flex-col items-center justify-center space-y-4">
            <Button
              type="primary"
              onClick={() => {
                logout()
                client.clearStore()
                setTimeout(() => {
                  history.push('/')
                  window.open('/', '_self')
                }, 500)
              }}
            >
              ログアウト
            </Button>
          </div>
        </Card>
      </div>
    )
  }

  const gotoPath = (path) => {
    if (warnBeforeLeave) {
      setGoingPath(path)
      setShowWarnModal(true)
      return
    }
    history.push(path)
  }

  return (
    <>
      <div className="flex bg-gray-50 h-screen">
        <div className="flex-auto flex flex-col bg-white dark:bg-gray-700 md:shadow-lg overflow-x-scroll">
          <div
            className='flex justify-center text-white bg-primary items-center fixed w-full h-20 z-50 print:hidden'
          >
            {customNavigate && (
              <div onClick={onClickNavigate} style={{ left: 0 }} className="flex items-center pl-8 absolute cursor-pointer">
                <img src="/images/ArrowNavbar.svg" />
                <span style={{ fontSize: 16, fontWeight: 900, paddingLeft: 10, color: '#E5E5E5' }}>
                  {defaultText}
                </span>
              </div>
            )}
            <div className="flex items-center text-base font-black">
              {navbar}
            </div>
            <div
              className='absolute right-4 flex flex-col cursor-pointer'
            >
              <Row className="space-x-6">
                {(authenticated || meData?.school) && <a href={`${process.env.REACT_APP_WEB_URL}/home`}>
                  <div
                    className="flex flex-col justify-center items-center text-white"
                  >
                    {HomeIcon}
                    <span className="font-black">
                      ホームに
                      <ruby>
                        戻<rt className="text-tiny font-black">もど</rt>
                      </ruby>
                      る
                    </span>
                  </div>
                </a>}
                <div
                  className="flex flex-col justify-center items-center"
                  onClick={() => gotoPath('/advise')}
                >
                  {PlayIcon}
                  <span className="font-black">
                    <ruby>
                      動画<rt className="text-tiny font-black">どうが</rt>
                    </ruby>
                    をみる
                  </span>
                </div>
              </Row>
            </div>
          </div>
          <div className='mt-20 h-full'>{children}</div>
        </div>
      </div>
      <div className="hidden print:block">{children}</div>
      <Modal
        visible={showWarnModal}
        onCancel={() => setShowWarnModal(false)}
        width={900}
        closeIcon={<CloseOutlined className="text-base" style={{ color: '#036EB8' }} />}
        footer={null}
        centered={true}
        bodyStyle={{ border: '3px solid #036EB8', borderRadius: 4 }}
      >
        <div className="p-10 flex flex-col justify-center items-center text-base font-black"
          style={{ color: '#B80303' }}>
          <div className="flex">
            {WarnIcon}
            <span>
              <ruby>本当<rt className="text-tiny">ほんとう</rt></ruby>
              にこのページから
              <ruby>移動<rt className="text-tiny">いどう</rt></ruby>
              しますか？
            </span>
          </div>
          <span>
            登録ボタンを
            <ruby>押<rt className="text-tiny">お</rt></ruby>
            していないデータは
            <ruby>削除<rt className="text-tiny">さくじょ</rt></ruby>
            されます
          </span>
          <Row className='mt-7'>
            <Button
              className="w-60 mx-2 bg-gray-150"
              size='large'
              onClick={() => setShowWarnModal(false)}
            >
              <ruby>
                戻
                <rt className="text-tiny font-black">もど</rt>
              </ruby>
              る
            </Button>
            <Button
              className="w-60 mx-2 bg-gray-150"
              size='large'
              onClick={() => {
                setWarnBeforeLeave(false)
                history.push(goingPath)
              }}
            >
              はい、
              <ruby>移動
                <rt className="text-tiny font-black">いどう</rt>
              </ruby>
              します
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  )
}
