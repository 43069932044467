import { Anchor } from '@sergeimeza/uikit-react'
import { NewAlphaIcon } from '@alpha/components'
import React from 'react'

const AlphaLogo = () => (
  <div className="flex items-center justify-center">{NewAlphaIcon}</div>
)

const ISMSLogo = () => (
  <img
    className="w-20"
    src="https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/isms.jpg"
    alt="isms"
  />
)

const SignInLayoutComponent: React.FC<any> = ({ children }) => {
  let layout = (
    <>
      <div className="min-h-screen flex flex-col justify-between sm:px-6 lg:px-8">
        {children}

        <div className="flex flex-col content-center justify-end">
          <div className="flex flex-col items-center">
            <span className="font-medium text-gray-700">【問い合わせ】</span>
            <span className="font-medium text-gray-700">
              alpha@pestalozzi-tech.com
            </span>
            <span className="font-medium text-gray-700">
              担当：Alpha新体力テストの測定・集計システム担当
            </span>
            <p className="font-medium text-gray-700">
              続行することで、Pestalozzi Technology株式会社の
              <Anchor
                href="https://pestalozzi-tech.com/alpha-terms/"
                className="text-primary-700 underline font-semibold "
              >
                サービス利用規約
              </Anchor>
              および、
              <Anchor
                href="https://pestalozzi-tech.com/privacy-policy/"
                className="text-blue-700 underline font-semibold"
              >
                <span className="px-1">個人情報保護ポリシー</span>
              </Anchor>
              に同意します。
            </p>
          </div>
          <div className="mx-auto">
            <ISMSLogo />
          </div>

          <div className="mt-3">
            <p className="text-gray-700 text-center text-xs">
              Copyright © Pestalozzi Technology {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </>
  )

  return layout
}

export const SignInLayout = Object.assign(SignInLayoutComponent, {
  AlphaLogo: AlphaLogo,
  ISMSLogo: ISMSLogo,
})
export default SignInLayout
