import { Dashboard } from '../layout/Dashboard'

import { DashboardNavGraphItem } from '../navGraph/DashboardNavGraphItem'
import { Row } from "antd";
import { useHistory } from "react-router-dom";

const HomePage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const history = useHistory()

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  const cardItems = [
    {
      url: 'test',
      title: (
        <>
          <ruby>
            測定結果<rt className="text-10px font-black">そくていけっか</rt>を
          </ruby>
          <ruby>
            入力<rt className="text-10px font-black">にゅうりょく</rt>
          </ruby>
        </>
      ),
      image: "/images/TestInput.png",
    },
    {
      url: 'result',
      title: (
        <>
          <ruby>
            測定結果<rt className="text-10px font-black">そくていけっか</rt>をみる
          </ruby>
        </>
      ),
      image: "/images/TestResult.png",
    },
    {
      url: 'questionnaire',
      title: (
        <>
          アンケートを
          <ruby>
            入力<rt className="text-10px font-black">にゅうりょく</rt>
          </ruby>
        </>
      ),
      image: "/images/QuestionInput.png",
    },
    {
      url: 'questionnaire_result',
      title: 'アンケートをみる',
      image: "/images/QuestionResult.png",
    },
  ]

  const Card = (cardItem, idx) => (
    <div key={idx} className="w-56 h-96 pt-16 bg-gray-150 rounded-10px cursor-pointer" onClick={() => history.push(`/${cardItem.url}`)}>
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span>{cardItem.title}</span>
      </Row>
      <Row justify="center" className="w-full">
        <img src={cardItem.image} alt={cardItem.title} />
      </Row>
    </div>
  )

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customNavigate
      defaultText='新体力テストに戻る'
      onClickNavigate={() => window.location.href = "https://kobe-kid-home.alpha-pestalozzi.com/physical-examination"}
    >
      <Row justify="center" align="middle" className="space-x-4 h-full">
        {
          cardItems.map((cardItem, idx) => Card(cardItem, idx))
        }
      </Row>
    </Dashboard>
  )
}

export default HomePage
