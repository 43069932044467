const findTokenUrl = (search?: string) => {
  let token = ''
  let redirectTo = ''

  if (typeof search === 'string') {
    const values = search.split('&')
    token = values[0].slice(7)
    redirectTo = values[1].slice(11)
  }
  return {
    token,
    redirectTo,
  }
}

export { findTokenUrl }
