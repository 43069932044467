import {Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row} from "antd";
import axios from 'axios';
import {EditIcon} from "../icons/EditIcon";
import {useEffect, useState} from "react";
import {CloseOutlined} from "@ant-design/icons";
import {useAlphaStore} from "~/context";
import {convertJapNumbers} from '~/utils'
import Api from '~/utils/api'
import {useHistory} from "react-router-dom";

const inputList = [
  {
    key: 'grip',
    tab: <ruby>握力
      <rt className="text-10px">あくりょく</rt>
    </ruby>,
    measurable: true,
    items: [
      {
        label: '右(1回目)',
        name: 'gripRight1',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
      {
        label: '右(2回目)',
        name: 'gripRight2',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
      {
        label: '左(1回目)',
        name: 'gripLeft1',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
      {
        label: '左(2回目)',
        name: 'gripLeft2',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'sitUps',
    tab: <ruby>上体起
      <rt className="text-10px">じょうたいお</rt>
      こし</ruby>,
    tab_width: 'w-24',
    measurable: true,
    items: [
      {
        name: 'sitUps',
        suffix: '回',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'bending',
    tab: <ruby>長座体前屈
      <rt className="text-10px">ちょうざたいぜんくつ</rt>
    </ruby>,
    tab_width: 'w-28',
    measurable: true,
    items: [
      {
        label: '(１回目）',
        name: 'bending1',
        suffix: 'cm',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
      {
        label: '（2回目）',
        name: 'bending2',
        suffix: 'cm',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'sideJump',
    tab: <ruby>反復横跳び
      <rt className="text-10px">はんぷくよことび</rt>
    </ruby>,
    tab_width: 'w-24',
    measurable: true,
    items: [
      {
        label: '（１回目）',
        name: 'sideJump1',
        suffix: '回',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
      {
        label: '（2回目）',
        name: 'sideJump2',
        suffix: '回',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
    ],
  },
  // Manual render
  // {
  //   tab: '体重',
  //   items: [
  //     {
  //       name: 'weight',
  //       suffix: 'kg',
  //     },
  //   ],
  // },
  // {
  //   tab: '座高',
  //   items: [
  //     {
  //       name: 'sittingHeight',
  //       suffix: 'kg',
  //     },
  //   ],
  // },
  {
    key: 'shuttleRun',
    tab: '20mシャトルラン',
    tab_width: 'w-36',
    measurable: true,
    items: [
      {
        name: 'shuttleRunCount',
        suffix: '回',
        min: 0,
        max: 200,
        rules: [
          {type: 'number', min: 0, max: 200, message: "0から200の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'enduranceRun',
    tab: '持久走',
    tab_width: 'w-16',
    measurable: true,
    items: [
      {
        name: 'runningTime',
        suffix: '分',
        rules: [
          {type: 'number', min: 0, max: 59, message: "0から59の数字を入力してください"}
        ],
      },
      {
        name: 'runningTimeSeconds',
        suffix: '秒',
        rules: [
          {type: 'number', min: 0, max: 59, message: "0から59の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'sprintRun',
    tab: <span>50m<ruby>走<rt className="text-10px">そう</rt></ruby></span>,
    tab_width: 'w-16',
    measurable: true,
    items: [
      {
        name: 'sprintRunSeconds',
        suffix: '秒',
        min: 0,
        max: 50,
        rules: [
          {type: 'number', min: 0, max: 50, message: "0から50の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'standingJump',
    tab: <span><ruby>立<rt className="text-10px">た</rt></ruby>ち<ruby>幅跳<rt
      className="text-10px">はばと</rt>び</ruby></span>,
    tab_width: 'w-24',
    measurable: true,
    items: [
      {
        label: '(１回目）',
        name: 'standingJump1',
        suffix: 'cm',
        min: 0,
        max: 300,
        rules: [
          {type: 'number', min: 0, max: 300, message: "0から300の数字を入力してください"}
        ],
      },
      {
        label: '（2回目）',
        name: 'standingJump2',
        suffix: 'cm',
        min: 0,
        max: 300,
        rules: [
          {type: 'number', min: 0, max: 300, message: "0から300の数字を入力してください"}
        ],
      },
    ],
  },
  {
    key: 'handballThrow',
    tab: <span>ボール<ruby>投<rt className="text-10px">な</rt></ruby>げ</span>,
    tab_width: 'w-24',
    measurable: true,
    items: [
      {
        label: '(１回目）',
        name: 'handballThrow1',
        suffix: 'm',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
      {
        label: '（2回目）',
        name: 'handballThrow2',
        suffix: 'm',
        min: 0,
        max: 99,
        rules: [
          {type: 'number', min: 0, max: 99, message: "0から99の数字を入力してください"}
        ],
      },
    ],
  },
]

export const TestInputs = ({testResults, student, testYear, hasEnduranceTest, isStudentInputActive}: {
  testResults: any[]
  student: any
  testYear: number
  hasEnduranceTest: boolean
  isStudentInputActive: boolean
}) => {

  const history = useHistory()
  const [showMemoModals, setShowMemoModals] = useState<any>({})
  const [oriMemo, setOriMemo] = useState<any>('')
  const [lastYearStudent, setLastYearStudent] = useState<any>({})
  const [loading, setLoading] = useState<any>(false)
  const [firstLoad, setFirstLoad] = useState<any>(true)
  const [form] = Form.useForm()

  const getInputList = () => {
    return inputList.filter(v => v.key !== (!hasEnduranceTest ? 'enduranceRun' : 'shuttleRun'))
  }

  const testResult = testResults?.find((d) => d.testYear === testYear)?.result

  const _isNotMeasurable = {}
  inputList.forEach(input => {
    _isNotMeasurable[input.key] = testResult?.[input.key]?.isNotMeasurable
  })
  const [isNotMeasurable, setIsNotMeasurable] = useState(_isNotMeasurable)

  const fetchLastYearStudent = async () => {
    await axios.get(
      `${process.env.REACT_APP_REST_API_URL}/alpha/v1/student/last-year-exam`,
      {
        params: {
          testYear,
        }
      }
    ).then((response) => {
      setLastYearStudent(response.data)
    }).catch((response) => {
      console.log(response)
    })
  }
  useEffect(() => {
    if (!firstLoad) {
      return
    }
    setFirstLoad(false)
    fetchLastYearStudent()
  }, [firstLoad])

  const showMemoModal = (key) => {
    setOriMemo(form.getFieldValue(`${key}Memo`) || testResult?.[key]?.memo)
    setShowMemoModals({...showMemoModals, [key]: true})
  }

  const hideMemoModal = (key, reset) => {
    if (reset) form.setFieldsValue({[`${key}Memo`]: oriMemo})
    setShowMemoModals({...showMemoModals, [key]: false})
  }

  const renderInput = (input) => {
    const _testKey = testResult?.[input.key]
    const _prevTestKey = lastYearStudent?.[input.key]
    const isEndurance = input.key === 'enduranceRun'
    let enduranceValue

    if (isEndurance && _prevTestKey?.runningTime) {
      enduranceValue = `${_prevTestKey.runningTime ?? 0}分${_prevTestKey.runningTimeSeconds ?? '0'}秒`
    }

    return <div key={`${input.key}-${student._id}`}>
      <Row>
        <div
          className={`h-8 ${input.tab_width ? input.tab_width : 'w-16'} bg-primary rounded-t-5px flex justify-center items-center text-white font-black`}>
          {input.tab}
        </div>
      </Row>
      <div className="flex justify-between items-center mb-3">
        <div className="rounded-5px rounded-5px-without-tl flex-1 bg-gray-150 p-1.5">
          <Row justify="center" align="middle">
            <Col span={22}>
              <Row>
                {
                  input.items.map((item, index) => {
                    return <Col key={`${input.key}-${student._id}-${index + 1}`} span={6}>
                      <Row align="bottom">
                        <Form.Item
                          className="mb-0-f"
                          name={item.name}
                          label={item.label ? <span className="text-10px">{item.label}</span> : null}
                          initialValue={isEndurance && _testKey?.[item.name] ? Number.parseInt(_testKey?.[item.name]) : _testKey?.[item.name]}
                          rules={input.measurable && isNotMeasurable[input.key] === true ? [] : item.rules}
                          validateTrigger={input.measurable && isNotMeasurable[input.key] === true ? [] : ["onSubmit", "onChange"]}
                        >
                          <InputNumber
                            className="border-primary"
                            type="number"
                            inputMode="decimal"
                            step="any"
                            disabled={isNotMeasurable[input.key]}
                            parser={displayValue => {
                              return convertJapNumbers(displayValue)
                            }}
                          />
                        </Form.Item>
                        {
                          item.suffix && (
                            <div className="pl-0.5">
                              <span className="text-10px">{item.suffix}</span>
                            </div>
                          )
                        }
                      </Row>
                    </Col>
                  })
                }
                {
                  input.measurable && (
                    <Col span={input.items.length >= 4 ? 24 : 6} className={`${input.items.length < 4 && 'ml-2'}`}>
                      {
                        input.items?.[0]?.label && input.items.length < 4 ? <div className="h-6"/> : <div/>
                      }
                      <Row align="middle">
                        <Form.Item
                          className="mb-0-f"
                          name={`${input.key}NotMeasurable`}
                          valuePropName="checked"
                          initialValue={_testKey?.isNotMeasurable}
                        >
                          <Checkbox
                            className="text-10px font-normal"
                            onChange={event => {
                              const newValue = {
                                ...isNotMeasurable,
                              }
                              newValue[input.key] = event.target.checked
                              setIsNotMeasurable(newValue)
                              form.validateFields()
                            }}
                          >
                            <ruby>今年度測定
                              <rt className="text-10px">こんねんどそくてい</rt>
                              なし
                            </ruby>
                          </Checkbox>
                        </Form.Item>
                      </Row>
                    </Col>
                  )
                }
              </Row>
            </Col>
            <Col span={2}>
              <Row justify="center" align="middle">
                <Button type="primary" icon={EditIcon} onClick={() => showMemoModal(input.key)}/>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="w-30 flex flex-col items-end relative">
          <div className="absolute -top-6">
            <ruby className="font-black text-10px">前年度
              <rt className="text-10px">ぜんねんど</rt>
              のスコア
            </ruby>
          </div>
          <Row justify="end" align="middle" className={`w-20 h-9 rounded-5px mr-1 p-2 speech-bubble-gray`}>
            {
              isEndurance
                ? <span className="relative z-10 text-base">{enduranceValue ?? '-'}</span>
                : <span
                  className="relative z-10 text-base">{`${_prevTestKey?.value ?? '-'} ${input.items[0].suffix}`}</span>
            }
          </Row>
        </div>
      </div>

      <Modal
        visible={showMemoModals[input.key]}
        onCancel={() => hideMemoModal(input.key, true)}
        width={800}
        closeIcon={<CloseOutlined className="text-base" style={{color: '#036EB8'}}/>}
        footer={null}
      >
        <div className="p-5 text-center">
          <label className="text-2xl font-black ">メモ</label>
          <Form.Item
            className="mb-0-f "
            name={`${input.key}Memo`}
            initialValue={_testKey?.memo}
          >
            <Input.TextArea style={{height: 400}} key={`${input.key}-${student._id}-memo`} bordered={false} autoFocus={true}/>
          </Form.Item>
          <div className="w-full flex justify-center">
            <Button
              className='w-60 rounded'
              type="primary"
              size="large"
              onClick={() => hideMemoModal(input.key, false)}
            >
              <ruby>登録
                <rt className="text-10px">とうろく</rt>
              </ruby>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  }

  const onFinish = async (values) => {
    let data = [
      {
        testYear: new Date().getFullYear(),
        studentId: student._id,
        gripTest: {
          grip: {
            gripRight1: values.gripRight1,
            gripRight2: values.gripRight2,
            gripLeft1: values.gripLeft1,
            gripLeft2: values.gripLeft2,
            isNotMeasurable: values.gripNotMeasurable,
            memo: values.gripMemo,
          }
        },
        sitUpsTest: {
          sitUps: {
            sitUps: values.sitUps,
            isNotMeasurable: values.sitUpsNotMeasurable,
            memo: values.sitUpsMemo,
          }
        },
        bendingTest: {
          bending: {
            bending1: values.bending1,
            bending2: values.bending2,
            isNotMeasurable: values.bendingNotMeasurable,
            memo: values.bendingMemo,
          }
        },
        sideJumpTest: {
          sideJump: {
            sideJump1: values.sideJump1,
            sideJump2: values.sideJump2,
            isNotMeasurable: values.sideJumpNotMeasurable,
            memo: values.sideJumpMemo,
          }
        },
        enduranceRunTest: {
          enduranceRun: {
            runningTime: values?.runningTime?.toString(),
            runningTimeSeconds: values.runningTimeSeconds,
            isNotMeasurable: values.enduranceRunNotMeasurable,
            memo: values.enduranceRunMemo,
          }
        },
        shuttleRunTest: {
          shuttleRun: {
            shuttleRunCount: values.shuttleRunCount,
            //shuttleRunGainOxygen?
            isNotMeasurable: values.shuttleRunNotMeasurable,
            memo: values.shuttleRunMemo,
          }
        },
        sprintRunTest: {
          sprintRun: {
            sprintRunSeconds: values.sprintRunSeconds,
            isNotMeasurable: values.sprintRunNotMeasurable,
            memo: values.sprintRunMemo,
          }
        },
        standingJumpTest: {
          standingJump: {
            standingJump1: values.standingJump1,
            standingJump2: values.standingJump2,
            isNotMeasurable: values.standingJumpNotMeasurable,
            memo: values.standingJumpMemo,
          }
        },
        handballThrowTest: {
          handballThrow: {
            handballThrow1: values.handballThrow1,
            handballThrow2: values.handballThrow2,
            isNotMeasurable: values.handballThrowNotMeasurable,
            memo: values.handballThrowMemo,
          }
        },
        sizeTest: {
          weight: values.weight,
          height: values.height,
        }
      }
    ]

    try {
      setLoading(true)
      const res = await Api.post('/alpha/student-result', data)
      if (res.status === 500) {
        message.error(`送信エラーが発生しました [${res.data.error}]`)
        return
      }
      message.success('登録完了')
      setLoading(false)
      history.push('/result')
    } catch (err) {
      message.error(`エラーが発生しました [${err}]`)
    }
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          gripRight1: testResult?.gripRight1?.value,
        }}
        hideRequiredMark={true}
      >
        <Row className="space-x-2">
          <Col span={6}>
            <Row>
              <div
                className={`h-8 w-16 bg-primary rounded-t-5px flex justify-center items-center text-white font-black`}>
                <ruby>身長
                  <rt className="text-10px">しんちょう</rt>
                </ruby>
              </div>
            </Row>
            <div className="flex justify-between items-center mb-3 w-60">
              <div className="w-30 rounded-5px rounded-5px-without-tl flex-1 bg-primary p-1.5">
                <div key={`height-${student._id}`}>
                  <div className="flex relative w-30">
                    <Form.Item
                      className="mb-0-f "
                      name="height"
                      rules={[{type: 'number', min: 0, max: 200, message: "0から200の数字を入力してください"}
                      ]}
                      initialValue={testResult?.sizeTest?.height}
                      validateTrigger={["onSubmit", "onChange"]}
                    >
                      <InputNumber
                        className="border-primary"
                        type="number"
                        step="any"
                        inputMode="decimal"
                        parser={displayValue => {
                          return convertJapNumbers(displayValue)
                        }}
                      />
                    </Form.Item>
                    <div className="flex items-end absolute left-24 top-4">
                      <span className="text-10px text-white">cm</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-30 flex flex-col items-end relative">
                <div className="pr-3 absolute -top-6">
                  <ruby className="font-black text-10px">前年度
                    <rt className="text-10px">ぜんねんど</rt>
                  </ruby>
                </div>
                <Row justify="end" align="middle" className={`w-20 h-9 rounded-5px p-2 speech-bubble-gray`}>
                <span className="relative z-10 text-base">
                  {`${lastYearStudent?.sizeTest?.height ?? '-'} cm`}
                </span>
                </Row>
              </div>
            </div>
          </Col>
          <Col span={6}>
            <Row>
              <div
                className={`h-8 w-16 bg-primary rounded-t-5px flex justify-center items-center text-white font-black`}>
                <ruby>体重
                  <rt className="text-10px">たいじゅう</rt>
                </ruby>
              </div>
            </Row>
            <div className="flex justify-between items-center mb-3 w-60">
              <div className="w-30 rounded-5px rounded-5px-without-tl flex-1 bg-primary p-1.5">
                <div key={`weight-${student._id}`}>
                  <div className="flex relative w-30">
                    <Form.Item
                      className="mb-0-f "
                      name="weight"
                      rules={[{type: 'number', min: 1, max: 200, message: "1から200の数字を入力してください"}
                      ]}
                      initialValue={testResult?.sizeTest?.weight}
                      validateTrigger={["onSubmit", "onChange"]}
                    >
                      <InputNumber
                        className="border-primary"
                        type="number"
                        step="any"
                        inputMode="decimal"
                        parser={displayValue => {
                          return convertJapNumbers(displayValue)
                        }}
                      />
                    </Form.Item>
                    <div className="flex items-end absolute left-24 top-4">
                      <span className="text-10px text-white">kg</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-30 flex flex-col items-end relative">
                <div className="pr-3 absolute -top-6">
                  <ruby className="font-black text-10px">前年度
                    <rt className="text-10px">ぜんねんど</rt>
                  </ruby>
                </div>
                <Row justify="end" align="middle" className={`w-20 h-9 rounded-5px p-2 speech-bubble-gray`}>
                <span className="relative z-10 text-base">
                  {`${lastYearStudent?.sizeTest?.weight ?? '-'} kg`}
                </span>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={12} className="pr-2 flex justify-evenly">
            <div className="h-full flex flex-col justify-between">
              {
                getInputList().slice(0, 4).map(input => renderInput(input))
              }
            </div>
          </Col>
          <Col span={12} className="pl-2">
            <div className="h-full flex flex-col justify-between">
              {
                getInputList().slice(4).map(input => renderInput(input))
              }
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-60" size="large" loading={loading}
                    disabled={!isStudentInputActive}>
              <ruby>登録
                <rt className="text-10px">とうろく</rt>
              </ruby>
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  )
}
