import {
  AuthProvider,
  Client,
  PrivateRoute,
  PublicPage,
  PublicRoute,
  RedirectRoute,
  getApolloClient,
  login,
  offlineLink,
} from '@alpha/core'
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { dashboardNavGraphItems } from './components/navGraph/DashboardNavGraphItem'
import AdvisePage from './components/pages/AdvisePage'
import AdvisePublicPage from './components/pages/AdvisePublicPage'
import HomePage from './components/pages/HomePage'
import LogoutPage from './components/pages/LogoutPage'
import MyPage from './components/pages/MyPage'
import QuestionnairePage from './components/pages/QuestionnairePage'
import QuestionnaireResultPage from './components/pages/QuestionnaireResultPage'
import RegisterUserPage from './components/pages/RegisterUserPage'
import SignInPage from './components/pages/SignInPage'
import TestInputPage from './components/pages/TestInputPage'
import TestResultPage from './components/pages/TestResultPage'
import { APP_VERSION } from './utils/constants'

// to determine whether new versions is available
console.info(APP_VERSION)

function App() {
  const [isOpen, setIsOpen] = useState(true)
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>()

  useEffect(() => {
    login()
  }, [])

  useEffect(() => {
    window.addEventListener('offline', () => {
      message.warning('オフラインになりました。')
    })
    window.addEventListener('online', () => {
      offlineLink.sync().then(() => {
        message.success('オンラインになりました。変更を送信しました。')
      })
    })
  }, [])

  useEffect(() => {
    ;(async () => {
      const ac = await getApolloClient(Client.StudentClient)
      setClient(ac)
    })()
  }, [])

  if (!client) return null

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/home"
              component={HomePage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'home')}
            />
            <PublicPage path="/advise" component={AdvisePage} />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/my_page"
              component={MyPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'my_page',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/questionnaire"
              component={QuestionnairePage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/questionnaire_result"
              component={QuestionnaireResultPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'questionnaire_result',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/test"
              component={TestInputPage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'test')}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/result"
              component={TestResultPage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'result')}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/register"
              component={RegisterUserPage}
              graphItem={dashboardNavGraphItems.find(
                (i) => i.key === 'register',
              )}
            />
            <PrivateRoute
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              path="/logout"
              component={LogoutPage}
              graphItem={dashboardNavGraphItems.find((i) => i.key === 'logout')}
            />
            <RedirectRoute path="/redirecting" component={<div />} />
            <PublicRoute path="/advice_pub" component={AdvisePublicPage} />
            <PublicRoute path="/" component={SignInPage} />
          </Switch>
        </Router>
      </AuthProvider>
    </ApolloProvider>
  )
}

export default App
