import { useMemo } from 'react'
import { Route } from 'react-router-dom'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../auth/AuthProvider'

export const PrivateRoute = ({
  component: Component,
  graphItem,
  isOpen = true,
  setIsOpen = {},
  ...rest
}) => {
  const authToken = useMemo(() => window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY), []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authToken) {
          return (
            <Component
              graphItem={graphItem}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              {...props}
            />
          )
        } else window.location.href = `${process.env.REACT_APP_WEB_URL}/login`
      }}
    />
  )
}
