import { SchoolCategory } from './generated/graphql'

export * from './generated/graphql'
export * from './auth/AuthProvider'
export * from './routing/PrivateRoute'
export * from './routing/PublicRoute'
export * from './routing/PublicPage'
export * from './routing/RedirectRoute'
export * from './services/api/ApolloClient'
export * from './services/api/commons'

export function calcShuttleRunGainOxygen(x: number): number {
  if (x < 0) {
    return 0
  }
  const seq = [2, 3, 2, 2]
  let n = 262
  for (let i = 2; i <= x; i++) {
    n += seq[(i - 1) % seq.length]
  }
  return n / 10
}

export function makeUnitName(scoreType: string): string {
  switch (scoreType) {
    case 'grip':
      return '(kg)'
    case 'sitUps':
      return '(回)'
    case 'bending':
      return '(cm)'
    case 'sideJump':
      return '(点)'
    case 'shuttleRun':
      return '(回)'
    case 'sprintRun':
      return '(秒)'
    case 'standingJump':
      return '(cm)'
    case 'handballThrow':
      return '(m)'
    case 'totalScore':
      return '(点)'
  }
  return ''
}

export function makeGradeName(c: SchoolCategory, grade: number): string {
  switch (c) {
    case SchoolCategory.ElementarySchool:
      return `小${grade}`
    case SchoolCategory.JuniorHighSchool:
      return `中${grade}`
    case SchoolCategory.HighSchool:
      return `高${grade}`
  }
  return ''
}

export function getSchoolYear(date: Date) {
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  return month < 4 ? year - 1 : year
}

export const hiraganaPattern = /[\u{3041}-\u{3093}]+/mu
export const hiraganaMessage = 'ひらがなで入力して下さい'
