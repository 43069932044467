import {useEffect, useState} from 'react'
import axios from 'axios';
import _ from "lodash"

import {Button, Col, Form, Input, List, message, Modal, Pagination, Radio, Row, Select, InputNumber, Checkbox} from 'antd'

import {QuestionnaireV3Result} from '@alpha/core'

import {Dashboard} from '../layout/Dashboard'
import CompletionLayout from "../layout/CompletionLayout";
import {DashboardNavGraphItem} from '../navGraph/DashboardNavGraphItem'
import {useHistory} from "react-router-dom";
import {CloseOutlined} from "@ant-design/icons";
import {WarnIcon} from "../icons/WarnIcon";
import { useStudentMeApiQuery, useStudentQuestionnaireResultApiQuery } from '~/utils/hooks';
import { KOBE } from '../shared/Questions'

const {Option} = Select

const QuestionnairePage = ({
                             graphItem,
                             isOpen,
                             setIsOpen,
                           }: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [testYear, setTestYear] = useState(2022)
  const [school, setSchool] = useState<any>(null)
  const [questionData, setQuestionData] = useState([])
  const [answeredQuestions, setAnsweredQuestions] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(0)
  const [maxPage, setMaxPage] = useState(2)
  const [confirming, setConfirming] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [isFormValid, setFormValid] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [unansweredQuestion, setUnansweredQuestion] = useState(null)
  const [isElementarySchool, setIsElementarySchool] = useState(false)
  const [loading, setLoading] = useState(true)

  const {data: resultData} = useStudentQuestionnaireResultApiQuery()
  const {data: meApiData} = useStudentMeApiQuery()

  const [firstLoad, setFirstLLoad] = useState(true)

  useEffect(() => {
    if (!resultData || !meApiData || !firstLoad) return
    setFirstLLoad(false)
    setLoading(true)
    const school = meApiData.school
    const questionnaireV3 = resultData?.survey
    let _isElementarySchool = school?.schoolCategoryCode === 'B1'
    const _type = _isElementarySchool ? 'elementary' : 'junior'
    let _answeredQuestions = questionnaireV3 ?? {}
    let questions
    let _limit = 5
    questions = KOBE
    let qd = questions.filter(d => d.type === _type)
    setQuestionData(qd)
    setAnsweredQuestions(_answeredQuestions)
    setMaxPage(Math.ceil(qd.length / _limit))
    setSchool(school)
    setIsElementarySchool(_isElementarySchool)
    setLimit(_limit)
    setLoading(false)
  }, [resultData, meApiData, testYear, isElementarySchool, firstLoad])

  const studentAttr = meApiData

  const getUnansweredQuestion = (answeredQuestions) => {
    for (const [key, value] of Object.entries(answeredQuestions)) {
      if (!value) {
        return key
      }
    }
  }

  const handleSave = async () => {
    setSubmitting(true)
    let students = []
    let questionnaire = {}

    const ua = getUnansweredQuestion(answeredQuestions)
    if (ua) {
      setShowErrorModal(true)
      setSubmitting(false)
      setUnansweredQuestion(questionData.find(q => q.id === ua)?.question ?? '')
      onChangeQuestionPage(1)
      return
    }

    for (const [key, value] of Object.entries(answeredQuestions)) {
      questionnaire[key] = value
      if ((key === 'q4' || key === 'q5') && Array.isArray(value)) {
        questionnaire[key] = value.join(',')
      }
    }

    students[0] = {
      schoolGrade: studentAttr.schoolGrade,
      schoolClass: studentAttr.schoolClass,
      schoolAttendanceNumber: studentAttr.attendanceNumber,
      questionnaire
    }

    await axios.post(
      `${process.env.REACT_APP_REST_API_URL}/alpha/v1/student/survey`,
      {
        survey: questionnaire
      }
    ).then(response => {
      let {message, error} = response.data
      if (message === 'ok') {
        form.resetFields()
        setSubmitted(true)
        setTimeout(() => {
          history.push('/questionnaire_result')
        }, 2000)
      } else {
        if (error) {
          message.error(error)
        } else {
          message.error('エラーが発生しました')
        }
      }
      setSubmitting(false)
    }).catch((response) => {
      console.error(response)
      message.error('エラーが発生しました')
    })
  }

  const renderQuestion = (_question) => {
    let _answered = answeredQuestions[_question.id]
    if (_question.id === 'q8' && (typeof _answered) !== 'object') {
      _answered = [0, 0, 0, 0, 0, 0, 0];
    }
    let answers
    if (_question.options) {
      answers = _question.id !== 'q8'
      ? <Form.Item
        name={_question.id}
        className="ant-form-item-custom"
        initialValue={_answered ? `${_answered}` : _answered}
      >
        {
          Array.isArray(_question.options)
            ? (
              <div className="w-48">
                <span>
                  <ruby className="text-xxs font-normal">
                    運動部名
                    <rt className="text-tiny font-normal">{isElementarySchool ? 'うんどうぶめい' : ''}</rt>
                  </ruby>
                </span>
                <Select
                  value={_answered}
                  className="rounded-5px pl-10"
                  dropdownAlign={{
                    offset: [0, -2], // align offset
                  }}
                  onChange={(value) => {
                    setAnsweredQuestions({...answeredQuestions, [_question.id]: value})
                  }}
                >
                  <>
                    {
                      _question.options.map((option) => (
                        <Option value={option} key={option}>
                          {option}
                        </Option>
                      ))
                    }
                  </>
                </Select>
              </div>
            )
            : _question.multiple ? <Checkbox.Group
              disabled={submitting}
              onChange={(values) => {
                setAnsweredQuestions({
                  ...answeredQuestions,
                  [_question.id]: values.sort().join(',')
                })
              }}
            >
              <Row className="w-full">
                {
                  Object.keys(_question.options).map((value, idx) => (
                    <Col key={idx} style={{width: ['q4', 'q5'].includes(_question.id) ? '50%' : '33%'}}>
                      <Checkbox value={value}>
                        {_question.options[value]}
                      </Checkbox>
                    </Col>
                  ))
                }
              </Row>
            </Checkbox.Group>
            : <Radio.Group disabled={submitting}>
              <Row className="w-full">
                {
                  Object.keys(_question.options).map((value, idx) => (
                    <Col key={idx} style={{width: ['q4', 'q5'].includes(_question.id) ? '50%' : '33%'}}>
                      <Radio value={value}>
                        {_question.options[value]}
                      </Radio>
                    </Col>
                  ))
                }
              </Row>
            </Radio.Group>
        }
      </Form.Item>
      : <Row className="w-full">
      {
        _question.options.map((value, idx: number) => (
          <Col style={{width: '33%'}} key={idx}>
            <Form.Item name={`${_question.id}.${idx}`} initialValue={_answered[idx]}>
              {value}
              <InputNumber
                key={idx}
                size="middle"
                type="number"
                min={0}
                max={1000}
                step={1}
                inputMode="numeric"
                style={{ margin: 8 }}
                onChange={(value) => {
                  const q8: any = (
                    (typeof answeredQuestions.q8) === 'object'
                    ? answeredQuestions.q8
                    : [0, 0, 0, 0, 0, 0, 0]
                  );
                  q8[idx] = value;
                  setAnsweredQuestions({
                    ...answeredQuestions,
                    q8
                  })
                }}
                defaultValue={_answered[idx]}
              />
              <label><ruby>分<rt className="text-xxs font-normal">ふん</rt></ruby></label>
            </Form.Item>
          </Col>
        ))
      }
    </Row>
    }

    const listWidth = school?.prefectureCode === 45
      ? 960
      : school?.prefectureCode === 9 ? 855 : 780

    return (
      <List.Item
        key={`question-${_question.id}`}
        style={{width: listWidth, borderStyle: 'none'}}
      >
        <>
          <div
            className="font-black"
          >
            {_question.question}
          </div>
          {answers}
        </>
      </List.Item>
    )
  }

  const renderConfirmQuestion = (_question) => {
    let chosenAnswer = answeredQuestions?.[_question.id]
    if ((_question.id === 'q4' || _question.id === 'q5') && Array.isArray(chosenAnswer)) {
      chosenAnswer = chosenAnswer.sort().join(',')
    }
    return <List.Item
      key={`confirm-${_question.id}`}
      style={{
        borderStyle: 'none'
      }}
    >
      <>
        <div
          className="font-black truncate"
        >
          {_question.question}
        </div>
        {
          _question.id !== 'q8'
            ? chosenAnswer
              ? <span className="pl-10">
                  {
                    Array.isArray(_question.options)
                      ? chosenAnswer
                      : _question.multiple
                        ? chosenAnswer.split(',').map(a => <><li>{_question?.options?.[a]}</li></>)
                        : _question?.options?.[chosenAnswer]
                  }
                </span>
              : <span className="pl-10"/>
            : _question.options.map((value, idx: number) => (
              <span className="pl-10">
              {value}<label>{chosenAnswer?.[idx] ?? 0}<ruby>分<rt className="text-xxs font-normal">ふん</rt></ruby></label>
              </span>
            ))
        }
      </>
    </List.Item>
  }

  const getPageData = () => {
    return questionData.slice((currentPage - 1) * limit, currentPage * limit)
  }

  const renderQuestionForm = () => {
    return (
      <Form
        form={form}
        onFinish={handleSave}
        layout="vertical"
        className="h-full"
        initialValues={{...answeredQuestions}}
      >
        <div className="w-full h-full flex flex-col items-center space-y-4 mt-10">
          <Row
            hidden={confirming}
            align="middle"
            justify="center"
            className={`border-3 border-primary rounded-xl py-2 ${confirming ? 'hidden' : ''}`}
            style={{...!confirming ? { width: 1000 } : {}}}
          >
            <List
              className="flex flex-col items-center space-y-1"
              itemLayout="vertical"
              dataSource={getPageData()}
              renderItem={(question) => renderQuestion(question)}
              bordered={false}
              pagination={false}
              loading={loading}
            />
          </Row>
          <Row
            hidden={confirming}
            align="middle"
            justify="center"
          >
            <Pagination current={currentPage}
                        onChange={onChangeQuestionPage}
                        total={questionData.length}
                        pageSize={limit}
                        size="default"
                        className="ant-pagination-custom"
            />
          </Row>
          <Row
            hidden={!confirming}
            align="middle"
            justify="center"
            className="mt-10 mb-2"
            style={{...!confirming ? { width: 1000 } : {}}}
          >
            <span className="text-lg font-black">
              <ruby>回答<rt className="text-tiny font-black">かいとう</rt></ruby>
              の
              <ruby>確認<rt className="text-tiny font-black">かくにん</rt></ruby>
            </span>
          </Row>
          <Row
            hidden={!confirming}
            align="middle"
            justify="center"
            className={`border-3 border-primary rounded-xl p-6`}
            style={{...!confirming ? { width: 1000 } : {}}}
          >
            <List
              className="flex flex-col items-center space-y-1"
              itemLayout="vertical"
              grid={{column: 2, gutter: 30}}
              dataSource={questionData}
              renderItem={(question) => renderConfirmQuestion(question)}
              bordered={false}
              pagination={false}
            />
          </Row>
          {
            currentPage > 1 && (
              <Row align="middle" justify="center">
                <Button
                  className="w-60"
                  type="primary"
                  size="large"
                  onClick={() => onChangeQuestionPage(currentPage - 1)}
                >
                  もどる
                </Button>
              </Row>
            )
          }
          {
            currentPage > 0 && !confirming && (
              <Row align="middle" justify="center">
                <Button
                  className="w-60"
                  type="primary"
                  size="large"
                  onClick={() => onChangeQuestionPage(currentPage + 1)}
                >
                  つぎへ
                </Button>
              </Row>
            )
          }
          {
            confirming && (
              <Row align="middle" justify="center">
                <Button
                  className="w-60"
                  type="primary"
                  size="large"
                  htmlType="submit"
                  disabled={isFormValid}
                >
                  <ruby>登録
                    <rt className="text-tiny">とうろく</rt>
                  </ruby>
                </Button>
              </Row>
            )
          }
        </div>
      </Form>
    )
  }

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  const onChangeQuestionPage = page => {
    const fieldValues = form.getFieldsValue()
    const q8Keys = [...Array(7)].map((_, id) => `q8.${id}`)
    if (fieldValues[q8Keys[0]] !== undefined) {
      for (const key of q8Keys) {
        delete fieldValues[key]
      }
    }
    setAnsweredQuestions({...answeredQuestions, ...fieldValues})
    setFormValid(!!getUnansweredQuestion(fieldValues))
    setCurrentPage(page)
    setConfirming(page === maxPage + 1)
  }

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customNavigate
      onClickNavigate={() => history.push('/home')}
    >
      {
        !submitted ? renderQuestionForm()
          : <CompletionLayout
            message={
              <ruby className="text-2xl font-black">登録完了
                <rt className="text-xxs">とうろくかんりょう</rt>
              </ruby>
            }
          />
      }
      <Modal
        visible={showErrorModal}
        onCancel={() => setShowErrorModal(false)}
        width={900}
        closeIcon={<CloseOutlined className="text-base" style={{color: '#036EB8'}}/>}
        footer={null}
        centered={true}
        bodyStyle={{border: '3px solid #036EB8', borderRadius: 4}}
      >
        <div className="p-10 flex flex-col justify-center items-center text-base font-black"
             style={{color: '#B80303'}}>
          <div className="flex space-x-5">
            {WarnIcon}
            <span>
                <ruby>入力<rt className="text-tiny">にゅうりょく</rt></ruby>
                されていない
                <ruby>質問<rt className="text-tiny">しつもん</rt></ruby>
                があるよ！
              </span>
          </div>
          <span className="pl-10">{unansweredQuestion}</span>
        </div>
      </Modal>
    </Dashboard>
  )
}

export default QuestionnairePage
