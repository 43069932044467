export type DashboardNavGraphItem = {
  key: string
  title: string | any
  tabIndex: number | undefined
}

export const dashboardNavGraphItems: DashboardNavGraphItem[] = [
  {
    key: 'home',
    title: 'ホーム',
    tabIndex: 1,
  },
  {
    key: 'advise',
    title: (
      <>
        <ruby>
          動画<rt className="text-10px font-black">どうが</rt>を
        </ruby>
        <ruby>
          見<rt className="text-10px font-black">み</rt>る
        </ruby>
      </>
    ),
    tabIndex: 2,
  },
  {
    key: 'my_page',
    title: 'マイページ',
    tabIndex: 3,
  },
  {
    key: 'questionnaire',
    title: (
      <>
        アンケートを
        <ruby>
          入力<rt className="text-10px font-black">にゅうりょく</rt>
        </ruby>
      </>
    ),
    tabIndex: 4,
  },
  {
    key: 'questionnaire_result',
    title: 'アンケートをみる',
    tabIndex: 5,
  },
  {
    key: 'test',
    title: (
      <>
        <ruby>
          測定結果<rt className="text-10px font-black">そくていけっか</rt>を
        </ruby>
        <ruby>
          入力<rt className="text-10px font-black">にゅうりょく</rt>
        </ruby>
      </>
    ),
    tabIndex: 6,
  },
  {
    key: 'result',
    title: (
      <>
        <ruby>
          測定結果<rt className="text-10px font-black">そくていけっか</rt>をみる
        </ruby>
      </>
    ),
    tabIndex: 7,
  },
  {
    key: 'logout',
    title: 'ログアウト',
    tabIndex: 8,
  },
  {
    key: 'code',
    title: 'コード登録',
    tabIndex: undefined,
  },
  {
    key: 'register',
    title: 'ユーザー情報登録',
    tabIndex: undefined,
  },
  {
    key: '',
    title: 'サインイン',
    tabIndex: undefined,
  },
]
