import { Redirect, Route } from 'react-router-dom'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '../auth/AuthProvider'

export const PublicRoute = ({ component: Component, ...rest }) => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authToken) {
          return <Component {...props} />
        } else window.location.href = `${process.env.REACT_APP_WEB_URL}/login`
      }}
    />
  )
}
