import {OnboardingLayout} from '@alpha/components'

import Video from '../cards/Video'
import {Row} from "antd";

const adviseMovies = [
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/009_introduction/video/009_introduction_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/009_introduction.jpg',
    title: '右代啓祐選手からのメッセージ',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/005_grip_strength/video/005_grip_strength_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/005_grip_strength.jpg',
    title: '新体力テストのコツ「握力」',
    desc: '右代啓祐選手の得点はいくつだろう？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/007_sit-up/video/007_sit-up_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/007_sit-up.jpg',
    title: '新体力テストのコツ「上体起こし」',
    desc: 'どんな筋肉が強くなる？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/008_flexibility/video/008_flexibility_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/008_flexibility.jpg',
    title: '新体力テストのコツ「長座体前屈」',
    desc: 'ワンポイントアドバイスを見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/006_sidestep/video/006_sidestep_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/006_sidestep.jpg',
    title: '新体力テストのコツ「反復横跳び」',
    desc: '右代啓祐選手の得点はいくつだろう？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/001_50m/video/001_50m_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/001_50m.jpg',
    title: '新体力テストのコツ「50m走」',
    desc: 'どんな筋肉が強くなる？動画を見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/002_20m_shuttle_run/video/002_20m_shuttle_run_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/002_20m_shuttle_run.jpg',
    title: '新体力テストのコツ「20mシャトルラン」',
    desc: 'ワンポイントアドバイスを見て得点アップに繋げてみよう！',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/003_broad_jump/video/003_broad_jump_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/003_broad_jump.jpg',
    title: '新体力テストのコツ「立ち幅跳び」',
    desc: '',
  },
  {
    url:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/004_throwing_ball/video/004_throwing_ball_480p.mp4',
    thumb:
      'https://storage.googleapis.com/cdn_pestalozzitech_onlinecoaching/alpha/thumb/004_throwing_ball.jpg',
    title: '新体力テストのコツ「ボール投げ」',
    desc: '',
  },
]

const AdvisePublicPage = () => {
  let title = <div className="theme-nav-title">新体力テストのコツ</div>

  return (
    <OnboardingLayout>
      <div className="pb-8">{title}</div>
      <Row justify="center">
        <Row style={{width: 966}}>
          {adviseMovies.map((m, index) => (
            <div className="w-1/3 pl-2" key={`advise-${index + 1}`}>
              <Video
                key={`advise-${index + 1}`}
                keyName={`advise-${index + 1}`}
                src={m.url}
                size={{ width: 282, height: 154 }}
                thumb={m.thumb}
                className="rounded-10px overflow-hidden"
              />
              <Row><span className="mt-1 text-black">{m.title}</span></Row>
            </div>
          ))}

        </Row>
      </Row>
    </OnboardingLayout>
  )
}

export default AdvisePublicPage
