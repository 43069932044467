import { useAreasQuery } from '@alpha/core'

import { Select } from 'antd'
const { Option } = Select

export const JapanAreaSelect = ({ value, onChange }) => {
  const { data: areaData } = useAreasQuery({
    fetchPolicy: 'cache-first',
  })

  return (
    <Select className="w-32" value={value} onChange={onChange}>
      <Option value="">地域</Option>
      <>
        {areaData?.areas?.data?.map((v) => (
          <Option value={v._id} key={v._id}>
            {v.name}
          </Option>
        ))}
      </>
    </Select>
  )
}
