import { useCitiesLazyQuery } from '@alpha/core'

import { useEffect } from 'react'
import { Select } from 'antd'
const { Option } = Select

export const JapanCitySelect = ({ prefectureId, value, onChange }) => {
  const [getCities, { data: cityData, loading }] = useCitiesLazyQuery({
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    if (!prefectureId) return
    getCities({ variables: { input: { prefecture: prefectureId } } })
    onChange('')
  }, [prefectureId, getCities, onChange])

  if (!prefectureId || loading || !cityData) {
    return null
  }

  return (
    <Select className="w-32" value={value} onChange={onChange}>
      <Option value={''}>市区町村</Option>
      <>
        {cityData?.cities?.data?.map((v) => (
          <Option value={v.name} key={v.name}>
            {v.name}
          </Option>
        ))}
      </>
    </Select>
  )
}
