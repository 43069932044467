// using Dayjs instead of Moment.js for DatePicker and TimePicker
import { Dayjs } from 'dayjs'
import * as React from 'react'
// import { PickerTimeProps } from 'antd/es/date-picker/generatePicker'
// import { Omit } from 'antd/es/_util/type'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'
import 'antd/es/date-picker/style/index'

export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

// export interface TimePickerProps
//   extends Omit<PickerTimeProps<Dayjs>, 'picker'> {}
export const TimePicker = React.forwardRef<any>((props, ref) => {
  return <DatePicker {...props} picker="time" mode={undefined} ref={ref} />
})
DatePicker.displayName = 'DatePicker'
