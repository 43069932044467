export const Card = ({ children, ...rest }) => {
  return (
    <div
      className="inline-block px-4 md:px-8 py-4 mb-16 md:py-8 bg-white rounded-md md:rounded-lg shadow-md md:shadow-lg border border-gray-200 print:hidden"
      {...rest}
    >
      {children}
    </div>
  )
}
