import {useApolloClient} from '@apollo/client'

import React, {useCallback} from 'react'
import {useHistory} from 'react-router-dom'

import {Button} from 'antd'
import {logout} from '@alpha/core'
import {Dashboard} from "../layout/Dashboard";
import {DashboardNavGraphItem} from "../navGraph/DashboardNavGraphItem";


const LogoutPage = ({
  graphItem,
  isOpen,
  setIsOpen,
}: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const AccountMenu = () => {
    const client = useApolloClient()
    const history = useHistory()

    const handleLogoutBtn = useCallback(async () => {
      await logout()
      await client.clearStore()
      setTimeout(() => {
        history.push('/')
        window.open('/', '_self')
      }, 500)
    }, [history, client])

    let title = <div className="theme-nav-title">{graphItem.title}</div>

    return (
      <Dashboard
        selectedMenu={graphItem.tabIndex}
        navbar={title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        customNavigate
        onClickNavigate={() => history.push('/my_page')}
      >
        <div className="h-full flex justify-center items-center overflow-hidden">
          <div style={{width: 800, height: 320}}
               className="bg-gray-150 rounded-10px flex flex-col justify-between items-center pt-7 pb-16 relative">
            <img className="absolute" style={{top: -128}} src="/images/AlphaWhale.png"/>
            <div className="text-2xl font-black">ログアウト</div>
            <div className="font-black pt-5">
              <ruby>本当
                <rt className="text-xxs">ほんとう</rt>
              </ruby>
              にログアウトしますか？
            </div>
            <div className="space-x-4">
              <a href={`${process.env.REACT_APP_WEB_URL}/logout`} onClick={handleLogoutBtn}>
                <Button className='w-60 rounded text-white' type="primary">
                  はい
                </Button>
              </a>
              <a href={`${process.env.REACT_APP_WEB_URL}/home`}>
                <Button className='w-60 rounded text-white' type="primary">
                  いいえ
                </Button>
              </a>
            </div>
          </div>
        </div>
      </Dashboard>
    )
  }

  return <AccountMenu/>
}

export default LogoutPage