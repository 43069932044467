import {useContext, useEffect, useState} from 'react'

import QRReader from 'react-qr-reader'

import {Button, Form, Input, message, Row} from 'antd'
import {EyeInvisibleOutlined, EyeTwoTone} from '@ant-design/icons';

import {AuthContext, useQrCodeStudentLazyQuery, useStudentLinkSchoolMutation,} from '@alpha/core'
import CompletionLayout from "../layout/CompletionLayout";

const requiredMessage = '入力して下さい'
const qrCodeOnlyMessage = '招待コードでログインすることができません。QRコードをスキャンしてログインしてください。'
const schoolAcceptQRCodeOnly = ['D127310000619', 'C127310000461']

const isDev = window.location.href?.endsWith('?dev')

const RegisterUserPage = () => {
  const auth = useContext(AuthContext)

  const [code, setCode] = useState<string | null>(null)
  const [studentData, setStudentData] = useState<any>(null)
  const [linkComplete, setLinkComplete] = useState<any>(false)
  const [qrCodeOnly, setQRCodeOnly] = useState<any>(false)
  //
  const [getStudent, {data}] = useQrCodeStudentLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })
  const [linkSchool, {loading}] = useStudentLinkSchoolMutation()

  const [form] = Form.useForm()
  //
  useEffect(() => {
    if (!code) return

    try {
      getStudent({
        variables: {input: {qrCode: code}},
      })
    } catch (error) {
      message.error(`エラー： ${error}`)
    }
  }, [code, getStudent])

  useEffect(() => {
    if (!data) return

    if (data.qrCodeStudent?.data) {
      setStudentData(data.qrCodeStudent.data)
    }

    if (code && data.qrCodeStudent?.error) {
      message.error(`エラー: ${data.qrCodeStudent.error}`)
    }
  }, [code, data])

  const onFinish = async (values) => {
    if (!auth.currentUser) {
      return
    }
    let uid = auth.currentUser.uid

    try {
      const linkVariables = {
        input: {
          invitationCode: values.invitationCode,
          firebaseId: uid,
        },
      }
      const res = await linkSchool({variables: linkVariables})
      if (res.errors) {
        message.error(`エラーが発生しました [${res.errors.join(',')}]`)
        return
      }
      const {studentLinkSchool} = res.data!
      if (!studentLinkSchool?.token) {
        message.error(`エラーが発生しました [${studentLinkSchool?.error}]`)
        return
      }
      if (studentLinkSchool?.token) {
        auth.setAccessToken(studentLinkSchool.token)
      }
      message.success('登録しました')
      setLinkComplete(true)

      setTimeout(() => {
        window.open('/reload', '_self')
      }, 2000)
    } catch (err) {
      message.error(`エラーが発生しました [${err}]`)
    }
  }

  const onFormSubmit = (values) => {
    if (schoolAcceptQRCodeOnly.some(code => values.invitationCode.slice(1).startsWith(code))) {
      message.error(qrCodeOnlyMessage, 10)
      form.setFieldsValue({'invitationCode': ''})
      setQRCodeOnly(true)
    } else {
      setCode(values.invitationCode)
    }
  }

  const handleScan = (data) => {
    if (data && data !== code) {
      form.setFieldsValue({invitationCode: data})
      setCode(data)
    }
  }

  const handleReturn = () => {
    form.setFieldsValue({invitationCode: null})
    setCode(null)
    setStudentData(null)
    getStudent({variables: {input: {qrCode: ''}}})
  }

  const handleError = (error) => {
    console.log('🚀 error:', error)
  }

  const QRCodeForm = (
    <div>
      <div className="flex justify-center items-center">
        <QRReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{
            width: '87%',
            backgroundColor: '#0d2784'
          }}
        />
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{invitationCode: code}}
        onFinish={onFormSubmit}
      >
        <div className="mt-6">
          <Form.Item
            hidden={process.env.NODE_ENV !== 'development' && !isDev}
            name="invitationCode"
            label={<label className="dark:text-white font-black">サインインコード</label>}
            rules={[{required: true, message: requiredMessage}]}
          >
            <Input.Password
              type="password"
              className="h-10 bg-gray-150"
              disabled={loading || qrCodeOnly}
              iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>)}
            />
          </Form.Item>
        </div>

        <Row justify="center" className="mt-4">
          <Form.Item>
            <Button type="primary" className="w-60" size="large" htmlType="submit" loading={loading}>
              サインイン
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  )

  const studentAttr = studentData?.attributes
  const schoolName = studentAttr?.schoolName

  const QRCodeConfirmView = (
    <div style={{width: 800}} className="bg-gray-150 py-20">
      <div className="space-y-9">
        <div className="text-xl font-bold text-center">あなたですか？</div>
        <div className="space-y-7 px-40 pr-20">
          <div className="flex">
            <div className="flex-1 text-left">
              <ruby className="font-black">名前
                <rt className="text-xxs font-black">なまえ</rt>
              </ruby>
            </div>
            <div className="text-lg text-center">
              {
                studentAttr?.familyNameHiragana && (
                  <div>
                    {studentAttr?.familyNameHiragana}{' '}
                    {studentAttr?.givenNameHiragana}
                  </div>
                )
              }
            </div>
            <div className="flex-1"/>
          </div>
          <div className="flex">
            <div className="flex-1 text-left">
              <ruby className="font-black">学校名
                <rt className="text-xxs font-black">がっこうめい</rt>
              </ruby>
            </div>
            <div className="text-lg text-center">
              {schoolName}
            </div>
            <div className="flex-1"/>
          </div>
          <div className="flex">
            <div className="flex-1 text-left">
              <ruby className="font-black mr-5">学年
                <rt className="text-xxs font-black">がくねん</rt>
              </ruby>
              <span className="text-lg">{studentAttr?.schoolGrade}</span>
            </div>
            <div className="flex-1 text-left">
              <ruby className="font-black mr-5">クラス</ruby>
              <span className="text-lg">{studentAttr?.schoolClass}</span>
            </div>
            <div className="flex-1 text-left">
              <ruby className="font-black mr-5">出席番号
                <rt className="text-xxs font-black">しゅっせきばんごう</rt>
              </ruby>
              <span className="text-lg">{studentAttr?.schoolAttendanceNumber}</span>
            </div>
          </div>
        </div>
        <div className="space-x-4 flex items-center justify-center">
          <div>
            <Button
              type="primary"
              className="w-60"
              size="large"
              onClick={() =>
                onFinish({
                  invitationCode: code,
                })
              }
            >
              はい
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              className="w-60"
              size="large"
              onClick={handleReturn}
            >
              いいえ
            </Button>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div
      className="w-full h-screen welcome-background flex justify-center overflow-hidden"
      style={{background: `url(/images/SignInBackground.png) no-repeat center center fixed`}}
    >
      {linkComplete
        ? <div className="flex flex-col relative">
            <div className="w-40 text-center absolute mt-11">
              <ruby className="text-2xl font-black">本人確認完了
                <rt className="text-10px">ほんにんかくにんかんりょう</rt>
              </ruby>
            </div>
            <div className="flex-1"><CompletionLayout message="ようこそ！"/></div>
            <div className="" />
          </div>
        : <div className="pt-11" style={{width: studentData ? 800 : 400}}>
          <h1 className="pb-4 text-center">
            {studentData
              ? <ruby className="text-2xl font-black">本人確認
                <rt className="text-10px">ほんにんかくにん</rt>
              </ruby>
              : <label className="text-2xl font-black">サインイン</label>}
          </h1>
          {studentData ? QRCodeConfirmView : QRCodeForm}
        </div>
      }
    </div>
  )
}

export default RegisterUserPage
