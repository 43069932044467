export const NewAlphaIcon = (
  <svg
    width="358"
    height="88"
    viewBox="0 0 358 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_142_84)">
      <path
        d="M7.90223 87.0255L0 82.4497L45.4689 0L77.5743 53.6389L103.639 34.7212L108.894 42.3264L74.9057 66.9851L45.7998 18.3457L7.90223 87.0255Z"
        fill="#036EB8"
      />
      <path
        d="M120.995 88.0001H113.114V78.7002H120.995V88.0001ZM103.639 88.0001H95.7577V78.7002H103.639V88.0001ZM86.3039 88.0001H78.4017V78.7002H86.2833V88.0001H86.3039ZM68.948 88.0001H61.0665V78.7002H68.948V88.0001ZM51.5921 88.0001H43.7105V78.7002H51.5921V88.0001ZM34.2568 88.0001H26.3752V78.7002H34.2568V88.0001Z"
        fill="#036EB8"
      />
      <path
        d="M178.317 67.9386C174.304 76.9419 166.629 81.7084 158.169 83.4244V74.0609C166.96 70.862 171.863 64.2101 171.863 51.9655V49.6988H181.234V52.5799C181.234 54.3594 181.131 56.0965 180.965 57.7912C187.047 55.7363 190.171 51.1817 191.329 45.5466H156.617V36.2891H202.272V38.1321C202.065 57.9607 189.736 65.926 178.317 67.9386Z"
        fill="#025FA1"
      />
      <path
        d="M208.188 84.3352V73.1287C212.202 69.5061 214.746 64.1889 214.746 51.2452V35.8653H224.221V50.652C224.221 71.0738 217.932 79.2086 208.188 84.3352ZM228.978 83.2124V35.8018H238.453V70.6925C244.804 68.5317 247.389 58.8716 247.72 50.7579H257.133C256.76 70.8619 247.555 82.6193 228.978 83.2124Z"
        fill="#025FA1"
      />
      <path
        d="M268.241 83.0426V73.8274C284.211 71.3277 293.954 61.6888 295.651 45.9276H262.366V36.6064H306.532V37.8563C306.532 68.1924 289.507 81.2632 268.241 83.0426Z"
        fill="#025FA1"
      />
      <path
        d="M337.313 70.3114C333.817 78.0013 327.053 82.1746 319.378 83.7423V74.866C326.991 72.112 331.128 66.5194 331.128 56.3297V55.0374H339.961V56.7745C339.961 58.0668 339.899 59.3167 339.796 60.503C344.285 58.7659 346.622 55.4187 347.636 51.2454H318.054V42.3691H358V44.53C357.855 61.3292 347.532 68.32 337.313 70.3114Z"
        fill="#025FA1"
      />
    </g>
    <defs>
      <clipPath id="clip0_142_84">
        <rect width="358" height="88" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
