import { useContext, useEffect, useRef, useState } from "react"
import Api from '../utils/api'
import { message } from 'antd'
import { AuthContext } from "~/alpha/core"
import axios from 'axios'
import querystring from 'querystring'

export const useAuthApiGet = (apiUrl: string, skipError: boolean = false): any => {
  const [data, setData] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [refresh, setRefresh] = useState(false)

  useEffect(() => {
    if (!firstLoad) {
      return
    }
    setFirstLoad(false)
    new Promise(async () => {
      try {
        setLoading(true)
        const { data } = await Api.get(apiUrl)
        setData(data)
        if (apiUrl === '/alpha/v1/school/me' && typeof data._id !== 'string') {
          window.localStorage.clear()
          delete axios.defaults.headers.common['Authorization']
        }
      } catch (e) {
        if (e.response.status === 401) {
          window.location.href = `https://kobe-kid-home.alpha-pestalozzi.com/logout`
        }
        if (!skipError) message.error('エラーが発生しました')
      } finally {
        setLoading(false)
      }
    })
  }, [])

  return {
    data: data?.attributes?.school ? { ...data, school: data?.attributes?.school } : data,
    loading,
    refresh: () => {
      setRefresh(!refresh)
    }
  }
}

const useAuthApiPost = (apiUrl: string, body: any, skipError: boolean = false): any => {
  const { currentUser } = useContext(AuthContext)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const fetchedRef = useRef(false)
  const [refresh, setRefresh] = useState(false)
  useEffect(() => {
    if (currentUser && !fetchedRef.current) {
      fetchedRef.current = true
      new Promise(async () => {
        try {
          setLoading(true)
          const { data } = await Api.post(apiUrl, body)
          setData(data)
        } catch (e) {
          console.log(e)
          if (!skipError) message.error('エラーが発生しました')
        } finally {
          setLoading(false)
          fetchedRef.current = false
        }
      })
    }
  }, [apiUrl, currentUser, body, refresh, skipError])
  return {
    data: currentUser ? data : undefined,
    loading,
    refresh: () => {
      setRefresh(!refresh)
    }
  }
}

// Alpha student apis
export const useStudentMeApiQuery = () => useAuthApiGet('/alpha/v1/student/me')
export const useStudentQuestionnaireResultApiQuery = () => useAuthApiGet('/alpha/v1/student/survey')
export const useStudentTestsApiQuery = () => useAuthApiGet('/alpha/v1/student/exams')
// Alpha school apis
export const useSchoolMeApiQuery = () => useAuthApiGet('/alpha/v1/school/me', true)
export const useSchoolGradesApiQuery = (
  params: { schoolId: string, testYear: number, schoolGrade?: number }
) => useAuthApiGet(`/alpha/v1/school/grades?${querystring.stringify(params)}`)
export const useSchoolSurveysApiQuery = (
  params: { schoolId: string, testYear: number, schoolGrade: number, schoolClass: number, offset: number, limit: number }
) => useAuthApiGet(`/alpha/v1/school/survey?${querystring.stringify(params)}`)
export const useSchoolQuestionnaireResultApiQuery = (params: string) => useAuthApiGet(`/alpha/v1/school/survey?${params}`)