import {useEffect, useState} from 'react'

import {Button, List, Row} from 'antd'
import {QuestionnaireV3Result} from '@alpha/core'

import {Dashboard} from '../layout/Dashboard'
import {DashboardNavGraphItem} from '../navGraph/DashboardNavGraphItem'
import {useHistory} from "react-router-dom";
import { useStudentMeApiQuery, useStudentQuestionnaireResultApiQuery } from '~/utils/hooks'
import { KOBE } from '../shared/Questions'

const QuestionnaireResultPage = ({
                                   graphItem,
                                   isOpen,
                                   setIsOpen,
                                 }: {
  graphItem: DashboardNavGraphItem
  isOpen: boolean
  setIsOpen
}) => {
  const history = useHistory()
  const [testYear, setTestYear] = useState(2022)
  const [questionData, setQuestionData] = useState([])
  const [answeredQuestions, setAnsweredQuestions] = useState<QuestionnaireV3Result>(null)
  const [isElementarySchool, setIsElementarySchool] = useState(false)
  const [school, setSchool] = useState<any>(null)

  const {data: resultData} = useStudentQuestionnaireResultApiQuery()
  const {data: meApiData} = useStudentMeApiQuery()
  const [firstLoad, setFirstLLoad] = useState(true)

  useEffect(() => {
    if (!resultData || !meApiData || !firstLoad) return
    setFirstLLoad(false)
    const school = meApiData.school
    const questionnaireV3 = resultData?.survey
    let _isElementarySchool = school?.schoolCategoryCode === 'B1'
    const _type = _isElementarySchool ? 'elementary' : 'junior'
    let _answeredQuestions = questionnaireV3
    const questions = KOBE
    let qd = questions.filter(d => d.type === _type)
    setQuestionData(qd)
    setAnsweredQuestions(_answeredQuestions)
    setSchool(school)
    setIsElementarySchool(_isElementarySchool)
  }, [resultData, meApiData, testYear, isElementarySchool, firstLoad])

  const renderQuestion = (_question) => {
    let chosenAnswer = answeredQuestions[_question.id]
    return <List.Item
      style={{
        borderStyle: 'none'
      }}
    >
      <>
        <div
          className="font-black truncate"
        >
          {_question.question}
        </div>
        {
          _question.id !== 'q8'
            ? chosenAnswer
              ? <span className="pl-10">
                  {
                    Array.isArray(_question.options)
                      ? chosenAnswer
                      : _question.multiple
                        ? chosenAnswer.split(',').map(a => <><li>{_question?.options?.[a]}</li></>)
                        : _question?.options?.[chosenAnswer]
                  }
                </span>
              : <span className="pl-10"/>
            : _question.options.map((value, idx: number) => (
              <span className="pl-10">
              {value}<label>{chosenAnswer?.[idx] ?? 0}<ruby>分<rt className="text-xxs font-normal">ふん</rt></ruby></label>
              </span>
            ))
        }
      </>
    </List.Item>
  }

  let title = <div className="theme-nav-title">{graphItem.title}</div>

  return (
    <Dashboard
      selectedMenu={graphItem.tabIndex}
      navbar={title}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      customNavigate
      onClickNavigate={() => history.push('/home')}
    >
      <div className="w-full h-full flex flex-col items-center space-y-4 mt-10">

        {
          answeredQuestions
            ? (
              <>
                <Row
                  align="middle"
                  justify="center"
                  className="mb-2"
                >
                  <span className="text-lg font-black">
                    <ruby>回答<rt className="text-tiny font-black">かいとう</rt></ruby>
                    の
                    <ruby>確認<rt className="text-tiny font-black">かくにん</rt></ruby>
                  </span>
                </Row>
                <Row
                  align="middle"
                  justify="center"
                  className={`border-3 border-primary rounded-xl p-6`}
                >
                  <List
                    className="flex flex-col items-center space-y-1"
                    itemLayout="vertical"
                    grid={{column: 2, gutter: 30}}
                    dataSource={questionData}
                    renderItem={(question) => renderQuestion(question)}
                    bordered={false}
                    pagination={false}
                  />
                </Row>
                <Row align="middle" justify="center">
                  <Button
                    className="w-60"
                    type="primary"
                    size="large"
                    onClick={() => history.push('/questionnaire')}
                  >
                    <ruby>編集
                      <rt className="text-tiny font-black">へんしゅう</rt>
                      する
                    </ruby>
                  </Button>
                </Row>
              </>
            ) : (
              <span className="text-2xl font-black">回答されない</span>
            )
        }

      </div>
    </Dashboard>
  )
}

export default QuestionnaireResultPage
