import { usePrefecturesLazyQuery } from '@alpha/core'

import { useEffect } from 'react'
import { Select } from 'antd'
const { Option } = Select

export const JapanPrefectureSelect = ({ areaId, value, onChange }) => {
  const [getPrefectures, { data: prefectureData }] = usePrefecturesLazyQuery({
    fetchPolicy: 'cache-first',
  })

  useEffect(() => {
    getPrefectures({ variables: { input: { areaId } } })
    onChange(0)
  }, [areaId, getPrefectures, onChange])

  return (
    <Select className="w-32" value={value} onChange={onChange}>
      <Option value={0}>都道府県</Option>
      <>
        {prefectureData?.prefectures?.data?.map((v) => (
          <Option key={v._id} value={v.code}>
            {v.name}
          </Option>
        ))}
      </>
    </Select>
  )
}
